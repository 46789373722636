import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Industry } from 'src/app/signup-api.g';
import { TranslatorService } from 'src/app/_services/translator-service';

@Component({
  selector: 'app-industry-card',
  templateUrl: './industry-card.component.html',
  styleUrls: ['./industry-card.component.scss']
})
export class IndustryCardComponent implements OnInit {
  @Input() cardConfig: Industry;
  @Input() imageFile: string;
  @Input() size = 'standard';
  @Input() text: string;
  @Input() isComingSoon: boolean;

  @Output() clicked = new EventEmitter<string>();

  constructor(public translator: TranslatorService) { }

  ngOnInit(): void { }

  setCardStyle(): any {
    return {
      cursor: this.isComingSoon ? 'not-allowed' : 'pointer',
      opacity: this.isComingSoon ? 0.5 : 1
    }
  }

  click(): any {
    if (!this.isComingSoon) {
      this.clicked.emit(this.cardConfig.id);
    }
  }
}
