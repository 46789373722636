import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, RouterModule, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralClient } from '../signup-api.g';
//import { ConsoleLoggerService } from '../_services/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {
  constructor(/*private consoleLogger: ConsoleLoggerService, */private generalClient: GeneralClient, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.generalClient.canAccess().toPromise().then(x => {
      if (x.canAccess) {
        return true;
      }

      return this.router.createUrlTree(['/hexa-pro']);
    }, _ => false);
  }
}
