import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthIds4Service } from './_services/authids4.service';
import { ConfigService } from './_services/config.service';
import { Location } from '@angular/common';
import { LoadingService } from './_services/loading.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslatorService } from './_services/translator-service';
import { UserClient } from './signup-api.g';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isLoading = false;
  isLogged: boolean;
  user: any;

  constructor(
    private router: Router,
    private location: Location,
    private configService: ConfigService,
    private authIds4Service: AuthIds4Service,
    private loadingService: LoadingService,
    private gtmService: GoogleTagManagerService,
    private translatorService: TranslatorService,
    private userClient: UserClient
  ) {

    this.location.onUrlChange((url, state) => {
      const gtmTag = {
        event: 'page',
        pageName: url
      };

      this.gtmService.pushTag(gtmTag);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingService.loading = true;
      }

      if (event instanceof NavigationEnd) {
        this.loadingService.loading = false;
        const gtmTag = {
          event: 'page',
          pageName: event.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });

    //Move this.
    this.authIds4Service.getLoggedUserFromCache().then(user => {
      if (user) {
        this.isLogged = true;
        this.user = user;
      }
    });

    this.translatorService.activeLanguage.listen(lang => {

      if (this.authIds4Service.loggedIn) {
        this.userClient.updateLanguage(lang).pipe(tap(() => {
          this.loadingService.showManualLoading();
        })).subscribe(x => {

          this.authIds4Service.signInSilent().then(_ => {
            this.translatorService.apiActiveLanguage.value = lang;
           });
        }, err => throwError(err), () => this.loadingService.hideManualLoading());
      }
    });
  }

  ngOnInit(): void {

    this.loadingService.loadingObserver.listen(x => {
      this.isLoading = x;
    });
  }

  title = 'SignUp';
}
