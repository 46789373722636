import { Injectable } from '@angular/core';
import { ValueObserver } from '../_base/value.observer';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public readonly loadingObserver = new ValueObserver<boolean>();
  private manualLoading = false;

  constructor() { }

  public get loading(): boolean {
    return this.loadingObserver.value;
  }

  public set loading(displayLoading: boolean) {
    this.loadingObserver.value = displayLoading || this.manualLoading;
  }

  public showManualLoading(): void{
    this.manualLoading = true;
    this.loading = this.loadingObserver.value;
  }

  public hideManualLoading(): void{
    this.manualLoading = false;
    this.loading = this.loadingObserver.value;
  }

  public showLoading(): void {
    this.loading = true;
  }

  public hideLoading(): void {
    this.loading = false;
  }
}
