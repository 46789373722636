import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { SignupDetailsViewModel, SubscriptionClient } from '../signup-api.g';


@Injectable({
  providedIn: 'root',
})
export class UserSubscriptionResolver implements Resolve<SignupDetailsViewModel> {
  constructor(private subscriptionClient: SubscriptionClient) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SignupDetailsViewModel> {
    return this.subscriptionClient.getUserSubscription().toPromise();
  }
}
