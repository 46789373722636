import { ISkin } from './template-family.models';

export const ColorsListDefault: ISkin[] = [
  // Scooter (Blue)
  {
    'primary-color': '#3AB7CF',
    'primary-color-text': '#000000',
    'secondary-color': '#73c1ea',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#3184A7',
    'header-gradient-to': '#3184A7',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#E2E9EE',
    'text-color': '#000000',
    'text-color-inactive': '#B6C1C1',
    'background-field': '#E4E9EB',
    'template-name': 'scooter',
    'border-color': '#3AB7CF',
  },
  // Mine Shaft (Black)
  {
    'primary-color': '#2C2C2C',
    'primary-color-text': '#000000',
    'secondary-color': '#7E7E7E',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#2C2C2C',
    'header-gradient-to': '#000000',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#EDEDED',
    'text-color': '#000000',
    'text-color-inactive': '#889191',
    'background-field': '#ECECEC',
    'template-name': 'mine-shaft',
    'border-color': '#2C2C2C',
  },
  // // Limeade (Green)
  {
    'primary-color': '#33BF00',
    'primary-color-text': '#000000',
    'secondary-color': '#7CD999',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#2A9B00',
    'header-gradient-to': '#268B00',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#F8FDF5',
    'text-color': '#000000',
    'text-color-inactive': '#889191',
    'background-field': '#F2F5F0',
    'template-name': 'limeade',
    'border-color': '#33BF00',
  },
  // Guardsman Red (Red)
  {
    'primary-color': '#CC0009',
    'primary-color-text': '#000000',
    'secondary-color': '#F88193',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#A30007',
    'header-gradient-to': '#860005',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#FFF6F5',
    'text-color': '#000000',
    'text-color-inactive': '#889191',
    'background-field': '#F7F1F0',
    'template-name': 'guardsman-red',
    'border-color': '#CC0009',
  },
  // Persian Green (Tial)
  {
    'primary-color': '#00B2A4',
    'primary-color-text': '#000000',
    'secondary-color': '#94D0D8',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#039589',
    'header-gradient-to': '#017970',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#F0FDFB',
    'text-color': '#000000',
    'text-color-inactive': '#889191',
    'background-field': '#EEF6F5',
    'template-name': 'persian-green',
    'border-color': '#00B2A4',
  },
  // Tangerine (Orange)
  {
    'primary-color': '#DD9200',
    'primary-color-text': '#000000',
    'secondary-color': '#E5BF23',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#D08900',
    'header-gradient-to': '#C68300',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#F7F6ED',
    'text-color': '#000000',
    'text-color-inactive': '#889191',
    'background-field': '#F2F2EC',
    'template-name': 'tangerine',
    'border-color': '#DD9200',
  },
];

export const ColorsListPlain: ISkin[] = [
  // Light
  {
    'primary-color': '#FFFFFF',
    'primary-color-text': '#191c1d',
    'secondary-color': '#73c1ea',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#191c1d',
    'header-gradient-to': '#191c1d',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#E2E9EE',
    'text-color': '#383F42',
    'text-color-inactive': '#B6C1C1',
    'background-field': '#E4E9EB',
    'template-name': 'light',
    'border-color': '#3AB7CF',
  },
  // Dark
  {
    'primary-color': '#191c1d',
    'primary-color-text': '#FFFFFF',
    'secondary-color': '#FFFFFF',
    'header-color-inactive': '#B6C1C1',
    'header-gradient-from': '#FFFFFF',
    'header-gradient-to': '#FFFFFF',
    'background-gradient-from': '#FFFFFF',
    'background-gradient-to': '#FFFFFF',
    'text-color': '#FFFFFF',
    'text-color-inactive': '#FFFFFF',
    'background-field': '#FFFFFF',
    'template-name': 'dark',
    'border-color': '#191c1d',
  },
];
