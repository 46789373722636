import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndustryService, SubscriptionInformation } from 'src/app/signup-api.g';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {
  @Input() industryService: IndustryService;
  @Input() subscriptionInformation: SubscriptionInformation;
  @Input() id: string;
  @Input() selected: boolean;

  @Output() toggleSelection = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  manageClick(): void {
    this.toggleSelection.emit(this.industryService.code);
  }

  fieldCardStyle(): any {
    return {
      backgroundColor: this.selected ? '#3ab7cf' : '#191c1d',
    };
  }
}
