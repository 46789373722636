<div class="main-content">
  <div class="header row">
    <span class="col">{{
      this.translator.translate("tell_us_a_bit_more_about") | async
    }}</span>
  </div>

  <div class="subheader row">
    <span class="col">{{
      this.translator.translate("your_business") | async
    }}</span>
  </div>

  <div class="error-message" *ngIf="this.hasErrors">{{ this.translator.translate('empty_fields_message') | async }}</div>
  <div class="error-message" *ngIf="this.duplicateNameError">{{ this.translator.translate('duplicate_name_error') | async }}</div>

  <div class="form-section">
    <div class="row">
      <div class="col-sm-0 col-md-1 col-lg-2"></div>
      <div class="col-sm-12 col-md-10 col-lg-8">
        <ng-container *ngFor="let ii of industryInformation">
          <div
            class="section-row row-name row"
            *ngIf="ii.questionType === QuestionType.Name"
          >
            <div class="pre-sentence col-12 col-md-4">
              {{ getBusinessNameText() | async }}
            </div>
            <div class="restaurant-name-input col-12 col-md-8">
              <app-text-box
                [showPlaceholder]="false"
                [text]="restaurantName"
                (textChange)="updateRestaurantName($event)"
                [maxLength]="20"
                [placeholder]="
                  this.translator.translate('type_your_text_ellipis') | async
                "
              ></app-text-box>
            </div>
          </div>

          <div
            class="section-row row-employees row"
            *ngIf="ii.questionType === QuestionType.NumberOfEmployees"
          >
            <div class="pre-sentence col-12 col-md-4">
              {{ this.translator.translate("i_have") | async }}
            </div>

            <div class="dropdown-box attached col-12 col-md-8">
              <app-dropdown-box
                [displayText]="numberOfEmployees"
                [itemsList]="dropdownEmployees"
                (clickEvent)="setNumberOfEmployees($event)"
              ></app-dropdown-box>
              <span class="post-sentence">{{ this.translator.translate("employees") | async }}</span>
            </div>
          </div>

          <div
            class="section-row row-type row"
            *ngIf="ii.questionType === QuestionType.RestaurantType"
          >
            <div class="pre-sentence col-12 col-md-4">
              {{ this.translator.translate("my_restaurant_is") | async }}
            </div>

            <div class="dropdown-box col-12 col-md-8">
              <app-dropdown-box
                [displayText]="restaurantType"
                [itemsList]="dropdownType"
                (clickEvent)="setRestaurantType($event)"
              ></app-dropdown-box>
            </div>
          </div>

          <div
            class="section-row row-website row"
            *ngIf="ii.questionType === QuestionType.HasWebsite"
          >
            <div class="pre-sentence col-12 col-md-4">
              {{ this.translator.translate("and_i") | async }}
            </div>

            <div class="dropdown-box col-12 col-md-8">
              <app-dropdown-box
                [displayText]="
                  haveWebsite ? dropdownWebsite[0] : dropdownWebsite[1]
                "
                [itemsList]="dropdownWebsite"
                (clickEvent)="setHaveWebsite($event)"
              ></app-dropdown-box>
            </div>
          </div>
        </ng-container>
        <div>
          <div class="section-row row-name row">
            <div class="pre-sentence col-12 col-md-4">
              {{ getBusinessInformationText() | async }}
            </div>
            <div class="restaurant-info-input col-12 col-md-8">
              <app-text-box
                [text]="address"
                (textChange)="updateAddress($event)"
                [placeholder]="this.translator.translate('address') | async"
              ></app-text-box>
            </div>
          </div>
          <div class="section-row row-name row">
            <div class="pre-sentence col-12 col-md-4"></div>
            <div class="restaurant-info-input col-12 col-md-8">
              <app-text-box
                [text]="city"
                (textChange)="updateCity($event)"
                [placeholder]="this.translator.translate('city') | async"
              ></app-text-box>
            </div>
          </div>
          <div class="section-row row-name row">
            <div class="pre-sentence col-12 col-md-4"></div>
            <div class="restaurant-info-postal-input col-12 col-md-4">
              <app-text-box
                [text]="postalCode"
                (textChange)="updatePostalCode($event)"
                [placeholder]="this.translator.translate('postal_code') | async"
              ></app-text-box>
            </div>
            <div class="restaurant-info-snowflake-input col-12 col-md-4">
              <app-text-box
                [text]="province"
                (textChange)="updateProvince($event)"
                [placeholder]="this.translator.translate('province') | async"
              ></app-text-box>
            </div>
          </div>
          <div class="section-row row-name row">
            <div class="pre-sentence col-12 col-md-4"></div>
            <div class="restaurant-info-input col-12 col-md-8">
              <app-text-box
                [text]="country"
                (textChange)="updateCountry($event)"
                [placeholder]="this.translator.translate('country') | async"
              ></app-text-box>
            </div>
          </div>
          <div class="section-row row-name row">
            <div class="pre-sentence col-12 col-md-4"></div>
            <div class="restaurant-info-input col-12 col-md-8">
              <app-text-box
                [text]="phone"
                (textChange)="updatePhone($event)"
                [placeholder]="
                  this.translator.translate('business_phone_number') | async
                "
              ></app-text-box>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-0 col-md-1 col-lg-2"></div>
    </div>
  </div>
</div>
