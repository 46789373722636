import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthIds4Service } from '../_services/authids4.service';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private authIds4Service: AuthIds4Service, private configService: ConfigService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {
      var culture = data['culture'] ? `&culture=${data['culture']}` : '';

      this.authIds4Service.getLoggedUserInfo().then((u) => {
        if (data['createAccount']) {
          this.authIds4Service.userManager.removeUser().then(() => {
            window.location.href = `${
              this.configService.config.ids4.authorityUrl
            }/Account/Signup?autoConfirm=true&autoLogin=true&sendWelcomeEmailSaas=true&returnUrl=${encodeURIComponent(this.configService.config.signup.baseUrl)}${culture}`;
          });
        } else if (!u || u.expired) {
          this.authIds4Service
            .signInSilent()
            .then(() => {
              const url = this.route.snapshot.url;
              if (url.length > 0 && url[0].path === 'login') {
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                  localStorage.removeItem('redirectUrl');
                  this.router.navigateByUrl(redirectUrl);
                } else {
                  this.router.navigateByUrl('/');
                }
              }
            })
            .catch(() => {
              this.authIds4Service.loginIds4();
            });
        } else {
          this.router.navigateByUrl('/');
        }
      });
    });
  }
}
