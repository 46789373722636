<div class="dropdown">
  <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <span class="text">{{ displayText }}</span>

    <img class="dropdown-chevron" src="/assets/images/icons/iconmonstr-arrow-65-dark.svg" alt="chevron" />
  </a>

  <div class="dropdown-menu">
    <a *ngFor="let item of itemsList;" class="dropdown-item" [ngClass]="{ 'selected': displayText === item }" (click)="manageClick(item)">{{ item }}</a>
  </div>
</div>
