import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/_services/config.service';
import { BehaviorSubject, Subscription } from 'rxjs';
//import * as moment from 'moment';
import { ValueObserver } from '../_base/value.observer';
import { UserClient } from '../signup-api.g';
import { AuthIds4Service } from './authids4.service';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  private readonly loggerFrom: string;
  private readonly storageName = 'signup-hexa-lang';
  private readonly supportedCultures = ['fr', 'en'];

  public useSubscription: Subscription;
  public translations: Promise<string>[];

  public readonly apiActiveLanguage: ValueObserver<string> = new ValueObserver<string>();
  public readonly activeLanguage: ValueObserver<string> = new ValueObserver<string>();

  constructor(private translateService: TranslateService, private configService: ConfigService) {
    this.translations = [];

    let initialLang = configService.config.signup.defaultLang;

    if (localStorage.getItem(this.storageName)) {
      initialLang = localStorage.getItem(this.storageName);
    } else {
      initialLang = this.getUserLanguage();
    }

    this.changeLanguage(initialLang);
  }

  private getUserLanguage(): string {
    for (const language of navigator.languages) {
      const lang = language.split('-')[0];

      if (this.supportedCultures.indexOf(lang) > -1) {
        return lang;
      }
    }

    return this.configService.config.signup.defaultLang;
  }

  public translate(key: string): Promise<string> {
    const keyLang = `${key}_${this.activeLanguage.value}`;

    if (this.translations[keyLang]) {
      return this.translations[keyLang];
    }

    if (key === null || key === '') {
      return new Promise(resolve => resolve);
    }

    return this.translateService
      .get(key)
      .toPromise()
      .then(result => {
        this.translations[keyLang] = Promise.resolve(result);
        return this.translations[keyLang];
      });
  }

  public setDefaultLanguage(langCode: string) {
    this.translateService.setDefaultLang(langCode);
  }

  public changeLanguage(langCode: string) {
    if (this.useSubscription) {
      this.useSubscription.unsubscribe();
    }

    this.useSubscription = this.translateService.use(langCode).subscribe(lang => {
      this.translateService.currentLang = langCode;
      this.activeLanguage.value = langCode;
      localStorage.setItem(this.storageName, langCode);

      //If we ever need moment uncomment
      //if (langCode === 'fr') {
      //  moment.locale('fr-ca');
      //} else {
      //  moment.locale(langCode);
      //}

      //this.portalEvents.language.value = langCode;
    });
  }
}
