<div class="progress-bar">
  <app-progress-top-bar
    [enableNext]="this.enableNext"
    [currentStep]="this.currentStep"
    (updateStepRequest)="next($event)"
    (goToPreviousStepRequest)="goToPreviousStep($event)"
  ></app-progress-top-bar>
</div>
<div class="container-fluid">
  <app-business-info-screen
    [hidden]="currentStep !== 1"
    (saveComplete)="businessInfoSaveComplete($event)"
    (canSaveStateChange)="canSave($event)"
    [saveRequest]="businessInfoSaveRequest"
  ></app-business-info-screen>

  <app-choose-service-screen
    [hidden]="currentStep != 2"
    (saveComplete)="chooseServicesSaveComplete($event)"
    (canSaveStateChange)="canSave($event)"
    [saveRequest]="chooseServicesSaveRequest"
    [branch]="branch"
  >
  </app-choose-service-screen>

  <app-survey-address-screen
    [hidden]="currentStep != 3"
    [initializationRequest]="surveyAddressInitializationRequest"
    (canSaveStateChange)="canSave($event)"
    (saveComplete)="surveyAddressSaveComplete($event)"
    [saveRequest]="surveyAddressSaveRequest"
    [subscriptionServiceId]="subscriptionServiceId"
    [defaultBusinessName]="businessName"
  ></app-survey-address-screen>

  <app-survey-editor-screen
    [hidden]="currentStep != 4"
    [initializationRequest]="surveyEditorInitializationRequest"
    [defaultBusinessName]="businessName"
    (saveComplete)="surveyEditorSaveComplete($event)"
    (canSaveStateChange)="canSave($event)"
    [saveRequest]="surveyEditorSaveRequest"
    [subscriptionServiceId]="subscriptionServiceId"
  ></app-survey-editor-screen>

  <app-loading-page [hidden]="currentStep != 5" [saveRequest]="loadingSaveRequest" [buildSurveyViewModel]="buildSurveyViewModel" [surveyAddress]="surveyAddress">
  </app-loading-page>
</div>
