<div class="main-content">
  <div class="header">
    Publish your survey. Gather data.
  </div>

  <div class="subheader">
    And so much more...
  </div>

  <div class="content-row">
    <div class="selling-points">
      <div class="selling-point">
        <div class="checkmark">
          <img src="./assets/images/checkmark.png" alt="checkmark">
        </div>

        <div class="text">
          This is selling point 1
        </div>
      </div>

      <div class="selling-point">
        <div class="checkmark">
          <img src="./assets/images/checkmark.png" alt="checkmark">
        </div>

        <div class="text">
          This is selling point 2
        </div>
      </div>

      <div class="selling-point">
        <div class="checkmark">
          <img src="./assets/images/checkmark.png" alt="checkmark">
        </div>

        <div class="text">
          This is selling point 3
        </div>
      </div>

      <div class="selling-point">
        <div class="checkmark">
          <img src="./assets/images/checkmark.png" alt="checkmark">
        </div>

        <div class="text">
          No Commitment. Unsubscribe whenever you want
        </div>
      </div>
    </div>

    <div class="plan trial">
      <div class="box">
        <div class="price">
          Free
        </div>

        <div class="pill">
          <span>15-DAY FREE TRIAL</span>
        </div>

        <div class="users">
          for 1 user
        </div>

        <div class="button">
          TRY IT FREE
        </div>
      </div>

      <div class="footnote">
        Enjoy the power of Hexa for a 15-day free period. Passed this period, you will be able to choose a monthly or annual subscription
      </div>
    </div>

    <div class="plan subscribe">
      <div class="box">
        <div class="price">
          <span class="small">CA$</span>
          <span class="big">{{ this.totalPrice }}</span>
          <span class="small">/month</span>
        </div>

        <div class="pill">
          <span>WHEN BILLED YEARLY</span>
        </div>

        <div class="users">
          <span>for</span>

          <div class="counter">
            <div class="count">
              {{ userCount }}
            </div>

            <div class="controls">
              <div class="arrow increase" (click)="increaseCount()">
                <img src="./assets/images/arrow_up.png" alt="arrow up">
              </div>

              <div class="arrow decrease" (click)="decreaseCount()">
                <img src="./assets/images/arrow_down.png" alt="arrow down">
              </div>
            </div>
          </div>

          <span>user</span>
        </div>

        <div class="button">
          SUBSCRIBE NOW
        </div>
      </div>

      <div class="footnote">
        You’ll be charged CA$120 (plus taxes) yearly until you cancel your subscription.
      </div>
    </div>
  </div>
</div>
