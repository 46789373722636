<div class="text-box-container">
  <div [attr.id]="refNumber" class="float-container" [ngClass]="{ active: text !== '' }">
    <label [attr.id]="'text' + refNumber + 1" [attr.for]="'text' + refNumber" [ngClass]="{ hintError: error }" [hidden]="!showPlaceholder">{{ placeholder }}</label>

    <input [type]="password ? 'password' : 'string'" [attr.id]="'text' + refNumber" name="text" [disabled]="disabled" [(ngModel)]="text" class="form-control" [ngClass]="{ error: error }" (ngModelChange)="emitTextChanged($event)" [hidden]="textarea" [attr.maxlength]="maxLength <= 0 ? null : maxLength" [style]="setInputPadding()" />

    <textarea [hidden]="!textarea" [attr.id]="'text' + refNumber" name="text" [disabled]="disabled" [(ngModel)]="text" class="form-control" [ngClass]="{ error: error }" (ngModelChange)="emitTextChanged($event)">{{ text }}</textarea>

    <div *ngIf="password" class="preview-password off"></div>
  </div>

  <div *ngIf="error">
    <p class="error-message">
      <span>{{ errorMessage }}</span>
    </p>
  </div>
</div>
