import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Config } from 'protractor';
import { Subject, Subscription } from 'rxjs';
import { BuildSurveyViewModel } from '../signup-api.g';
import { ConfigService } from '../_services/config.service';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit, OnChanges, OnDestroy {
  private saveRequestSubscription: Subscription;
  public showSpinner: boolean;
  public portalUrl: string;
  private buildSurveySubscription: Subscription;

  @Input() public saveRequest: Subject<void>;
  @Input() public buildSurveyViewModel: BuildSurveyViewModel;
  @Input() public surveyAddress: string;

  constructor(
    public config: ConfigService,
    public translator: TranslatorService) {
    this.showSpinner = true;
  }

  ngOnInit(): void {
    this.portalUrl = this.config.config.Portal.BaseUrl;
  }

  ngOnDestroy() {
    this.saveRequestSubscription.unsubscribe();
    this.buildSurveySubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.saveRequest) {
      if (this.saveRequestSubscription) {
        this.saveRequestSubscription.unsubscribe();
      }

      this.saveRequestSubscription = this.saveRequest.subscribe(
        () => {
          if (this.buildSurveyViewModel) {
            window.location.href = this.config.config.Portal.BaseUrl + '/voc/' + this.buildSurveyViewModel.subscriptionServiceId + '/project/' + this.buildSurveyViewModel.projectId + '/results/';
          }
          else{
            window.location.href = this.portalUrl;
          }
        }
      );
    }
  }

  goToDashboard() {
    console.log(this.buildSurveyViewModel);
    if (this.buildSurveyViewModel) {
      window.location.href = this.config.config.Portal.BaseUrl + '/voc/' + this.buildSurveyViewModel.subscriptionServiceId + '/project/' + this.buildSurveyViewModel.projectId + '/results/';
    }
    else{
      window.location.href = this.portalUrl;
    }
  }
}
