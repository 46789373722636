import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { ChooseIndustryScreenComponent } from './choose-industry-screen/choose-industry-screen.component';
import { CreationStepsComponent } from './creation-steps/creation-steps.component';
import { HexaProComponent } from './hexa-pro/hexa-pro.component';
import { LoggedoutComponent } from './loggedout/loggedout.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PlanPageComponent } from './plan-page/plan-page.component';
import { AccessGuard } from './_guards/access.guard';
import { AuthGuard } from './_guards/auth.guard';
import { IndustriesResolver } from './_resolvers/industries.resolver';
import { IndustryResolver } from './_resolvers/industry.resolver';
import { IndustryInformationResolver } from './_resolvers/industryInformation.resolver';
import { IndustryServicesResolver } from './_resolvers/industryServices.resolver';
import { UserSubscriptionResolver } from './_resolvers/userSubscription.resolver';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'loggedout',
    component: LoggedoutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'industries/:industryId/wizard',
    component: CreationStepsComponent,
    resolve: {
      industryInformation: IndustryInformationResolver,
      industry: IndustryResolver,
      industryServices: IndustryServicesResolver,
      userSubscription: UserSubscriptionResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'industries/:industryId/wizard/businessinfo',
    redirectTo: 'industries/:industryId/wizard', pathMatch: 'full'
  },
  {
    path: 'industries/:industryId/wizard/choosefield',
    redirectTo: 'industries/:industryId/wizard', pathMatch: 'full'
  },
  {
    path: 'industries/:industryId/wizard/surveyaddress',
    redirectTo: 'industries/:industryId/wizard', pathMatch: 'full'
  },
  {
    path: 'industries/:industryId/wizard/surveyeditor',
    redirectTo: 'industries/:industryId/wizard', pathMatch: 'full'
  },
  {
    path: 'industries/:industryId/wizard/publish',
    redirectTo: 'industries/:industryId/wizard', pathMatch: 'full'
  },
  {
    path: 'choose-industry',
    component: ChooseIndustryScreenComponent,
    resolve: { industries: IndustriesResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: '',
    component: ChooseIndustryScreenComponent,
    resolve: { industries: IndustriesResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: 'hexa-pro',
    component: HexaProComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
