import { Component, OnInit } from '@angular/core';
import { AuthIds4Service } from '../_services/authids4.service';
import { Router } from '@angular/router';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-callback',
  template: '',
})
export class CallbackComponent implements OnInit {
  constructor(
    private authIds4Service: AuthIds4Service,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    // Receives the callback from Ids4 to complete the login handshake
    // Url define at 'environment.ids4RedirectUrl'
    this.authIds4Service
      .loginCallback()
      .then((user) => {
        // console.log('in callback');
        // console.log(user);
        this.authIds4Service.setLoggedUser(user, false);

        const redirectUrl = localStorage.getItem('redirectUrl');

        //this.logger.info('RedirectUrl', 'CallbackComponent', redirectUrl);
        //console.log(redirectUrl);
        if (redirectUrl) {
          localStorage.removeItem('redirectUrl');
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch((reason) => {
        //console.log(reason);
        //console.log('failed');
        //this.logger.error(reason, 'CallbackComponent');
        this.router.navigate(['/login']);
      });
  }
}
