<div class="main-content" [style]="{ opacity: welcomePopup || uploadLogoTrigger ? '.25' : '1' }" (click)="dismissPopupClick()">
  <div class="container-fluid">
    <div class="content col-12">
      <div class="choose-template" (click)="showTemplates()">
        <div class="title">
          <span>{{ this.translator.translate("choose_template") | async }}</span>
          <span [ngClass]="{'d-none': this.overrideTemplatesDisplay}">{{ this.translator.translate("edit") | async }}</span>
          <span [ngClass]="{'d-none': !this.overrideTemplatesDisplay}">{{ this.translator.translate("confirm") | async }}</span>
        </div>
      </div>
      <div class="left-frame" [ngClass]="{show: this.overrideTemplatesDisplay}">
        <div class="choose-template-section">
          <div class="heading row">
            <span class="col">{{ this.translator.translate("choose_template") | async }}</span>
          </div>
          <div class="templates-section">
            <div class="template-block-container row" *ngFor="let template of this.templateFamilies; let i = index">
              <app-template-block
                [template]="template"
                [selected]="selectedTemplate === template"
                [selectedColor]="selectedTemplate === template ? selectedSkinColor : template.colorScheme[0]['primary-color']"
                (selectTemplate)="setTemplate($event)"
                *ngIf="template.noBackground"
              ></app-template-block>
              <app-template-block
                [template]="template"
                [selected]="selectedTemplate === template"
                [selectedColor]="selectedTemplate === template ? selectedSkinColor : template.colorScheme[0]['primary-color']"
                [templateImageSource]="
                  selectedTemplate === template
                    ? getImageUrl('background-' + selectedSkinColor['template-name'] + '.jpg')
                    : getImageUrl('background-' + template.colorScheme[0]['template-name'] + '.jpg')
                "
                (selectTemplate)="setTemplate($event)"
                *ngIf="!template.noBackground"
              ></app-template-block>
              <div class="template-name">{{ this.translator.translate(template.name) | async }}</div>
              <div class="choose-skin-section" *ngIf="selectedTemplate === template">
                <div class="heading row">
                  <span class="col">{{ this.translator.translate("choose_skin_color") | async }}</span>
                </div>
                <div class="color-blocks row">
                  <div class="color-block-container" *ngFor="let color of template.colorScheme">
                    <app-color-block [color]="color" [selected]="selectedSkinColor === color" (selectSkinColor)="setSkinColor($event)"></app-color-block>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div class="mid-frame" [ngClass]="{hide: this.overrideTemplatesDisplay}">
        <div class="content-section" [ngStyle]="{ 'background-image': this.backgroundImageUrl, 'background-color': this.selectedSkinColor['primary-color'] }">
          <div class="error-message" *ngIf="this.uploadError">
            {{ this.translator.translate("logo_upload_failed") | async }}
          </div>
          <div class="error-message" *ngIf="this.uploadError">
            {{ this.translator.translate("logo_upload_failed_instruction") | async }}
          </div>
          <div class="logo-section row">
            <div class="middle-section col">
              <div class="logo-placeholder" (click)="this.uploadLogo($event)" [ngStyle]="{ 'border-color': this.selectedSkinColor['primary-color-text'] }">
                <div class="top-row">
                  <img
                    *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') === -1"
                    class="img-fluid img-medium mx-auto d-block"
                    src="{{ this.uploadedLogoPreview ? this.uploadedLogoPreview : 'assets/images/survey-editor-screen/logo-placeholder-icon.svg' }}"
                  />
                  <img
                    *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') > -1"
                    class="img-fluid img-medium mx-auto d-block"
                    src="{{ this.uploadedLogoPreview ? this.uploadedLogoPreview : 'assets/images/survey-editor-screen/logo-placeholder-icon-white.svg' }}"
                  />
                  <div *ngIf="!this.uploadedLogoPreview" class="heading">
                    <div class="row">
                      <span [ngStyle]="{ color: this.selectedSkinColor['text-color'] }">{{ this.translator.translate("add_your_logo") | async }}</span>
                    </div>
                    <div class="row">
                      <span class="text-link" [ngStyle]="{ color: this.selectedSkinColor['text-color'] }">{{ this.translator.translate("click_here") | async }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title-section">
            <div class="row title-section-row">
              <div class="title-text" [hidden]="editSurveyTitle">
                <div class="title-text-en" [ngStyle]="{ color: this.selectedSkinColor['text-color'] }" [hidden]="editSurveyTitle">
                  {{ surveyTitleCurrentLang }}
                </div>
              </div>
              <div class="title-text-input" [hidden]="!editSurveyTitle">
                <app-text-box
                  class="title-text-box"
                  placeholder="{{ this.translator.translate('in_english') | async }}"
                  [text]="surveyTitleEN"
                  (textChange)="setSurveyTitleEN($event)"
                ></app-text-box>
                <app-text-box
                  class="title-text-box"
                  placeholder="{{ this.translator.translate('en_francais') | async }}"
                  [text]="surveyTitleFR"
                  (textChange)="setSurveyTitleFR($event)"
                ></app-text-box>
              </div>
            </div>
            <div class="row edit-button">
              <div class="edit-title" (click)="manageEditSurveyTitleClick()" [ngStyle]="{ color: this.selectedSkinColor['text-color'] }">
                <img *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') === -1" src="assets/images/survey-editor-screen/edit-title-icon.svg" />
                <img *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') > -1" src="assets/images/survey-editor-screen/edit-title-icon-white.svg" />
                <div class="text" [hidden]="editSurveyTitle" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }">
                  {{ this.translator.translate("edit_title") | async }}
                </div>
                <div class="text" [hidden]="!editSurveyTitle" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }">
                  {{ this.translator.translate("done") | async }}
                </div>
              </div>
            </div>
          </div>
          <div class="subheader-section">
            <span class="title-en" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }"
              >{{ this.translator.translate("this_survey_is_based_on_your_most_recent_experience_with") | async }} {{ this.defaultBusinessName }}</span
            >
          </div>
          <div class="intro-section">
            <div class="content">
              <div class="text-english row" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }" [hidden]="editIntroductionText">
                <span class="col">{{ introductionTextCurrentLang }}</span>
              </div>
              <div class="edit-english row" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }" [hidden]="!editIntroductionText">
                <app-text-box
                  [text]="introductionTextEN"
                  [placeholder]="this.translator.translate('in_english') | async"
                  [textarea]="true"
                  (textChange)="setIntroductionTextEN($event)"
                ></app-text-box>
              </div>
            </div>
            <div class="content">
              <div class="edit-french row" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }" [hidden]="!editIntroductionText">
                <app-text-box
                  [text]="introductionTextFR"
                  [placeholder]="this.translator.translate('en_francais') | async"
                  [textarea]="true"
                  (textChange)="setIntroductionTextFR($event)"
                ></app-text-box>
              </div>
            </div>
            <div class="content" (click)="manageEditIntroductionTextClick()">
              <div class="edit-section" [ngStyle]="{ color: this.selectedSkinColor['primary-color-text'] }">
                <img *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') === -1" src="assets/images/survey-editor-screen/edit-title-icon.svg" />
                <img *ngIf="this.selectedSkinColor['template-name'].indexOf('dark') > -1" src="assets/images/survey-editor-screen/edit-title-icon-white.svg" />
                <span class="edit-intro-text-text" [hidden]="editIntroductionText">{{ this.translator.translate("edit") | async }}</span>
                <div class="edit-intro-text-text" [hidden]="!editIntroductionText">
                  {{ this.translator.translate("done") | async }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-frame">
        <div class="btn-preview row">
          {{ this.translator.translate("preview") | async }}
        </div>
        <div class="text row">
          <span class="col">{{ this.translator.translate("dont_worry_you_will_be_able_to_edit_it_later") | async }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-upload-logo
  class="welcome-popup upload-logo-popup"
  (dismissComponent)="this.uploadLogoDismiss($event)"
  (uploadData)="this.logoSelected($event)"
  [hidden]="!uploadLogoTrigger"
></app-upload-logo>
<div class="custom-modal" [hidden]="!welcomePopup" (click)="manageWelcomeClick()">
  <div class="welcome-popup" [hidden]="!welcomePopup">
    <div class="heading">
      {{ this.translator.translate("welcome_to_the_survey_editor") | async }}
    </div>
    <div class="card-section">
      <div class="step-card add-logo">
        <div class="card-heading">
          {{ this.translator.translate("add_your_logo") | async }}
        </div>
        <div class="icon-box">
          <img class="icon" src="assets/images/survey-editor-screen/add-logo-icon.png" />
        </div>
      </div>
      <div class="step-card edit-survey">
        <div class="card-heading">
          {{ this.translator.translate("edit_survey_title_and_intro") | async }}
        </div>
        <div class="icon-box">
          <img class="icon" src="assets/images/survey-editor-screen/edit-survey-icon.png" />
        </div>
      </div>
    </div>
    <!-- <div class="btn-okay" (click)="manageWelcomeClick()">
      {{ this.translator.translate('ok_got_it') | async }}
    </div> -->
  </div>
</div>
