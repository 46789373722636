import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { PlanPageComponent } from './plan-page/plan-page.component';
import { ChooseIndustryScreenComponent } from './choose-industry-screen/choose-industry-screen.component';
import { IndustryCardComponent } from './choose-industry-screen/industry-card/industry-card.component';
import { CreationStepsComponent } from './creation-steps/creation-steps.component';
import { ProgressTopBarComponent } from './shared/progress-top-bar/progress-top-bar.component';
import { BusinessInfoScreenComponent } from './business-info-screen/business-info-screen.component';
import { TextBoxComponent } from './shared/text-box/text-box.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BranchClient, GeneralClient, IndustryClient, OrganizationClient, SubscriptionClient, SurveyAddressesClient, SurveyBuilderClient, UserClient } from './signup-api.g';
import { DropdownBoxComponent } from './shared/dropdown-box/dropdown-box.component';
import { ChooseServiceScreenComponent } from './choose-service-screen/choose-service-screen.component';
import { ServiceCardComponent } from './choose-service-screen/service-card/service-card.component';
import { SurveyAddressScreenComponent } from './survey-address-screen/survey-address-screen.component';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { UrlService } from './_services/url.service';
import { ConfigService } from './_services/config.service';
import { AuthIds4Service } from './_services/authids4.service';
import { NoCacheHeadersInterceptor } from './_base/cache.interceptor';
import { IdTokenHeadersInterceptor } from './_base/token.interceptor';
import { ColorBlockComponent } from './survey-editor-screen/color-block/color-block.component';
import { SurveyEditorScreenComponent } from './survey-editor-screen/survey-editor-screen.component';
import { TemplateBlockComponent } from './survey-editor-screen/template-block/template-block.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationInterceptor } from './_base/authentication.interceptor';
import { LoginComponent } from './login/login.component';
import { TranslatorService } from './_services/translator-service';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomMissingTranslationHelper } from './custom-missing-translation-helper';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UploadLogoComponent } from './upload-logo/upload-logo.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccessGuard } from './_guards/access.guard';
import { HexaProComponent } from './hexa-pro/hexa-pro.component';
import { LoadingService } from './_services/loading.service';
import { LoadingInterceptor } from './_base/loading.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

export function initialize(config: ConfigService): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      config.init.then(() => {
        resolve(true);
      });
    });
  };
}

export function jwtOptionsFactory(urlService: UrlService) {
  return {
    tokenGetter: () => urlService.tokenGetter(),
    whitelistedDomains: [
      'signup.projectmarvel.io',
      'signup.hulk.cool',
      'signup.ironman.cool',
      'signup.hexa.app'
    ],
    blacklistedRoutes: [
      'ids4.projectmarvel.io',
      'sit-identityuiweb-xrjoyqcaii5nu.azurewebsites.net',
      'identityuiweb-puscbe3bc4dmu.azurewebsites.net',
      'id.hulk.cool',
      'id.ironman.cool',
      'id.hexa.app'
    ]
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    PlanPageComponent,
    ChooseIndustryScreenComponent,
    IndustryCardComponent,
    CreationStepsComponent,
    ProgressTopBarComponent,
    BusinessInfoScreenComponent,
    TextBoxComponent,
    DropdownBoxComponent,
    ChooseServiceScreenComponent,
    ServiceCardComponent,
    SurveyAddressScreenComponent,
    SurveyEditorScreenComponent,
    ColorBlockComponent,
    TemplateBlockComponent,
    LoadingPageComponent,
    LoadingSpinnerComponent,
    LoginComponent,
    UploadLogoComponent,
    PageNotFoundComponent,
    HexaProComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [UrlService]
      }
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHelper },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MW4SCZM'
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [ConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdTokenHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    ConfigService,
    AuthGuard,
    LoadingService,
    AccessGuard,
    GeneralClient,
    IndustryClient,
    AuthIds4Service,
    TranslateService,
    TranslatorService,
    UserClient,
    UrlService,
    IndustryClient,
    SubscriptionClient,
    OrganizationClient,
    SurveyAddressesClient,
    SurveyBuilderClient,
    BranchClient,
    SurveyBuilderClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
