export class UserDto {
  constructor() {}
  id: string;
  familyName: string;
  givenName: string;
  name: string;
  userName: string;
  website: string;
  roles: string[];
  jobTitle: string;
  timeZone: string;
  culture: string;
  authScheme: string;
  ctEnabled: boolean;
}

// export class TokenUserInfo {
//   sub: string;
//   family_name: string;
//   given_name: string;
//   name: string;
//   preferred_username: string;
//   website: string;
//   role: string[];
// }
