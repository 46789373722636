import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Industry, IndustryClient, OrganizationClient, OrganizationPlaceholderViewModel } from '../signup-api.g';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-choose-industry-screen',
  templateUrl: './choose-industry-screen.component.html',
  styleUrls: ['./choose-industry-screen.component.scss'],
})
export class ChooseIndustryScreenComponent implements OnInit {
  public industries: Industry[];

  constructor(private organizationClient: OrganizationClient, public translator: TranslatorService, protected service: IndustryClient, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.translator.apiActiveLanguage.listen(lang => {
      if(lang){
        this.service.getIndustries().subscribe(x => {
          this.industries = x;
        });
      }
    });

    this.route.data.subscribe(data => {
      this.industries = data.industries;
      this.createOrganizationPlaceholder().subscribe(_ => {
      });
    });

    console.log('industries: ', this.industries);
  }

  cardClick(id: string) {
    this.router.navigate(['/industries', id, 'wizard']);
  }

  private createOrganizationPlaceholder(): Observable<OrganizationPlaceholderViewModel> {
    return this.organizationClient.placeceholder();
  }
}
