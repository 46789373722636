import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  Branch,
  BranchClient,
  Industry,
  IndustryClient,
  IndustryService,
  SetupBranchServicesViewModel
} from '../signup-api.g';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-choose-service-screen',
  templateUrl: './choose-service-screen.component.html',
  styleUrls: ['./choose-service-screen.component.scss'],
})
export class ChooseServiceScreenComponent implements OnInit {
  public industryServices: IndustryService[];
  public selectedCodes: string[];
  public hasErrors: boolean;
  public industry: Industry;
  public static selectedCodesLength: number;

  @Input() public branch: Branch;
  @Input() public saveRequest: Subject<void>;
  @Output() public canSaveStateChange = new EventEmitter<boolean>();
  @Output() public saveComplete = new EventEmitter<
    SetupBranchServicesViewModel
  >();

  saveRequestSubscription: Subscription;

  constructor(
    public translator: TranslatorService,
    private service: IndustryClient,
    private route: ActivatedRoute,
    private branchClient: BranchClient
  ) {
    this.selectedCodes = [];
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.industryServices = data.industryServices;
      this.industry = data.industry;
      this.sortFields();

      this.translator.apiActiveLanguage.listen(lang => {
        if (lang) {
          this.route.params.subscribe(x => {
            this.service.getIndustryServices(x['industryId']).subscribe(x => {
              this.industryServices = x;
              this.sortFields();
            });

            this.service.getIndustry(x['industryId']).subscribe(x => {
              this.industry = x;
            });
          })
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.branch) {
      this.selectedCodes = [];

      if (this.branch) {
        for (let index = 0; index < this.branch.services.length; index++) {
          const element = this.branch.services[index];
          this.selectedCodes.push(element.code);
        }
        this.canSaveStateChange?.emit(this.selectedCodes.length > 0);
      }

      ChooseServiceScreenComponent.selectedCodesLength = this.selectedCodes.length;
    }

    if (changes && changes.saveRequest) {
      if (this.saveRequestSubscription) {
        this.saveRequestSubscription.unsubscribe();
      }

      this.saveRequestSubscription = this.saveRequest.subscribe(
        (observable) => {
          this.hasErrors = false;

          try {
            this.saveSubscriptionInfo().subscribe((result) => {
              this.saveComplete.emit(result);
            });
          } catch (err) {
            console.error(err);
            this.saveComplete.emit(null);

            this.hasErrors = true;
          }
        }
      );
    }
  }

  sortFields(): void {
    this.industryServices.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });

  }

  saveSubscriptionInfo(): Observable<SetupBranchServicesViewModel> {
    const selectedIndustries = this.industryServices.filter((is) =>
      this.selectedCodes.includes(is.code)
    );
    return this.branchClient.setupBranchServices(
      this.branch.id,
      selectedIndustries
    );
  }

  toggleSelect(code: string): void {
    if (this.selectedCodes.includes(code)) {
      this.selectedCodes = this.selectedCodes.filter((c) => c !== code);
    } else {
      this.selectedCodes.push(code);
    }

    ChooseServiceScreenComponent.selectedCodesLength = this.selectedCodes.length;
    this.canSaveStateChange?.emit(this.selectedCodes.length > 0);
  }

  getAsABusinessText(): Promise<string> {
    const industryCode = this.industry.code;
    return this.translator.translate(`as-a-${industryCode}`);
  }
}
