import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss'],
})
export class UploadLogoComponent implements OnInit {
  public url: string | ArrayBuffer;
  public file: File;

  @Output() uploadData = new EventEmitter<File>();
  @Output() dismissComponent = new EventEmitter<void>();

  constructor(public translator: TranslatorService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  upload(): void {
    this.uploadData.emit(this.file);
    this.dismiss();
  }

  dismiss(): void {
    this.dismissComponent.emit();
  }

  onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      this.file = event.target.files[0];

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }
}
