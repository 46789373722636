<div class="main-content">
  <div class="industry-icon">
    <img src="./industry/{{industry.id}}/logo" alt="industry icon" />
  </div>

  <div class="header row">
    <span class="as-a-text">{{ getAsABusinessText() | async }} </span><span class="measure-text">{{ this.translator.translate('what_would_you_like_to_measure') | async }}</span>
  </div>

  <div class="extra-heading row">
    <span class="col">{{ this.translator.translate('please_select_deselect_the_cards') | async }}</span>
  </div>

  <div class="error-message" *ngIf="this.hasErrors">{{ this.translator.translate('empty_fields_message') | async }}</div>

  <div class="field-cards row">
    <div class="field-card-item" *ngFor="let is of industryServices;">
      <app-service-card [industryService]="is" [selected]="selectedCodes.includes(is.code)" (toggleSelection)="toggleSelect($event)"></app-service-card>
    </div>
  </div>
</div>
