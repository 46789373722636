import { ColorsListDefault, ColorsListPlain } from './colors-list.config';

export interface ISkin {
  'primary-color': string;
  'primary-color-text': string;
  'secondary-color': string;
  'header-color-inactive': string;
  'header-gradient-from': string;
  'header-gradient-to': string;
  'background-gradient-from': string;
  'background-gradient-to': string;
  'text-color': string;
  'text-color-inactive': string;
  'background-field': string;
  'template-name': string;
  'border-color': string;
}

export interface ITemplateFamily {
  default: boolean;
  name: string;
  noBackground: boolean;
  colorScheme: ISkin[];
}

/* ALL TEMPLATES CONFIGURATION */

/* Templates that will show on the left bar list */
export class FamilyTemplates {
  families: ITemplateFamily[] = [
    new FamilyTemplateDefault(),
    new FamilyTemplatePlain(),
  ];
}

export class FamilyTemplateDefault implements ITemplateFamily {
  public default = true;
  public name = 'concrete';
  public noBackground = false;
  public colorScheme = ColorsListDefault;
}

export class FamilyTemplatePlain implements ITemplateFamily {
  public default = false;
  public name = 'plain';
  public noBackground = true;
  public colorScheme = ColorsListPlain;
}
