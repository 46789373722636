<div class="progress-top-bar">
  <div class="btn-back" [ngClass]="{ disabled: currentStep === 1 }" (click)="changeStep(-1)">
    <i class="caret-arrow"></i>
    <span class="btn-text capitalize">{{ this.translator.translate('back') | async }}</span>
  </div>

  <div class="prog-bar">
    <span [class]="currentStep === 1 ? 'step active' : 'step'">
      <div class="step-circle" [style]="{ backgroundColor: currentStep > 1 ? '#3ab7cf' : 'transparent' }">
        <div class="current-step" [style]="{ display: currentStep === 1 ? 'block' : 'none' }"></div>
      </div>

      <div class="step-text" [class.disabled]="isDisabled(1)" (click)="this.goToStep(1)" [style]="setStepTextColor(1)">{{this.translator.translate('step_1') | async}}</div>
    </span>
    <span [class]="currentStep === 2 ? 'step active' : 'step'">
      <div class="horizontal-line"><hr /></div>

      <div class="step-circle" [style]="{ backgroundColor: currentStep > 2 ? '#3ab7cf' : 'transparent' }">
        <div class="current-step" [style]="{ display: currentStep === 2 ? 'block' : 'none' }"></div>
      </div>

      <div class="step-text" [class.disabled]="isDisabled(2)" (click)="this.goToStep(2)" [style]="setStepTextColor(2)">{{this.translator.translate('step_2') | async}}</div>
    </span>
    <span [class]="currentStep === 3 ? 'step active' : 'step'">
      <div class="horizontal-line"><hr /></div>

      <div class="step-circle" [style]="{ backgroundColor: currentStep > 3 ? '#3ab7cf' : 'transparent' }">
        <div class="current-step" [style]="{ display: currentStep === 3 ? 'block' : 'none' }"></div>
      </div>

      <div class="step-text" [class.disabled]="isDisabled(3)" (click)="this.goToStep(3)" [style]="setStepTextColor(3)">{{this.translator.translate('step_3') | async}}</div>
    </span>
    <span [class]="currentStep === 4 ? 'step active' : 'step'">
      <div class="horizontal-line"><hr /></div>

      <div class="step-circle" [style]="{ backgroundColor: currentStep > 4 ? '#3ab7cf' : 'transparent' }">
        <div class="current-step" [style]="{ display: currentStep === 4 ? 'block' : 'none' }"></div>
      </div>

      <div class="step-text" [class.disabled]="isDisabled(4)" (click)="this.goToStep(4)" [style]="setStepTextColor(4)">{{this.translator.translate('step_4') | async}}</div>
    </span>
    <span [class]="currentStep === 5 ? 'step active' : 'step'">
      <div class="horizontal-line"><hr /></div>

      <div class="step-circle" [style]="{ backgroundColor: currentStep > 5 ? '#3ab7cf' : 'transparent' }">
        <div class="current-step" [style]="{ display: currentStep === 5 ? 'block' : 'none' }"></div>
      </div>

      <div class="step-text" [class.disabled]="isDisabled(5)" [style]="setStepTextColor(5)">{{this.translator.translate('step_5') | async}}</div>
    </span>
  </div>

  <button class="btn-next" [disabled]="!enableNext" (click)="changeStep(1)">
    <span class="btn-text capitalize">{{ (this.setNextButtonText(currentStep) | async) }}</span>
    <i class="caret-arrow"></i>
  </button>
</div>
