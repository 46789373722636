import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-box',
  templateUrl: './dropdown-box.component.html',
  styleUrls: ['./dropdown-box.component.scss']
})
export class DropdownBoxComponent implements OnInit {
  @Input() displayText: string;
  @Input() itemsList: [];

  @Output() clickEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

  manageClick(item: string): void {
    this.clickEvent.emit(item);
  }
}
