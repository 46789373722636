<div class="main-content container-fluid">
  <div class="header row">
    <span class="first-color">{{ this.translator.translate('lets_talk') | async }}&nbsp;</span>
    <span class="second-color">{{ this.translator.translate('about_you') | async }}</span>
  </div>

  <div class="subheader row">
    <span>{{ this.translator.translate('please_select_your_industry') | async }}</span>
  </div>

  <div class="industry-cards container-fluid">
    <div class="industry-row row">
      <div class="industry" *ngFor="let card of this.industries">
        <app-industry-card [isComingSoon]="!card.isEnabled" [size]="'standard'" [cardConfig]="card" (clicked)="cardClick($event)"></app-industry-card>
      </div>
    </div>
  </div>
</div>
