<div class="error">
  <div>
    <img src="/assets/images/generic-error-illustration.svg" />
  </div>

  <div class="error-content">
    <div class="title">
      <strong>{{ translator.translate('error_title') | async }}</strong>
      <div class="error-label-section">
        <span class="error-label"> {{ translator.translate('error_404') | async }}</span>
      </div>
    </div>
    <div class="description">
      <p>{{ translator.translate('error_404_description') | async }}</p>
      <p>{{ translator.translate('error_persists') | async }}</p>
    </div>
  </div>
</div>
