import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslatorService } from 'src/app/_services/translator-service';
import { ISkin, ITemplateFamily } from '../_models/template-family.models';

@Component({
  selector: 'app-template-block',
  templateUrl: './template-block.component.html',
  styleUrls: ['./template-block.component.scss'],
})
export class TemplateBlockComponent implements OnInit, OnChanges {
  @Input() template: ITemplateFamily;
  @Input() templateImageSource: string;
  @Input() selected: boolean;
  @Input() disabled = false;
  @Input() selectedColor: ISkin;

  @Output() selectTemplate = new EventEmitter<ITemplateFamily>();

  public imageSource: string;

  constructor(public translator: TranslatorService) {}

  ngOnInit(): void {
   // this.imageSource = 'assets/images/survey-editor-screen/template-icon.png';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const imageSourceChange = changes.templateImageSource;

    if (imageSourceChange && imageSourceChange.currentValue) {
      this.imageSource = imageSourceChange.currentValue;
    }
  }

  manageClick(): void {
    if (!this.disabled) {
      this.selectTemplate.emit(this.template);
    }
  }
}
