import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Industry, IndustryClient } from '../signup-api.g';

@Injectable({
  providedIn: 'root',
})
export class IndustriesResolver implements Resolve<Industry[]> {
  constructor(private industryClient: IndustryClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Industry[]> {
    return this.industryClient.getIndustries().toPromise();
  }
}
