import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { IndustryInformation, IndustryClient } from '../signup-api.g';

@Injectable({
  providedIn: 'root',
})
export class IndustryInformationResolver implements Resolve<IndustryInformation[]> {
  constructor(private industryClient: IndustryClient) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IndustryInformation[]> {
    return this.industryClient.getIndustryInformation(route.params.industryId).toPromise();
  }
}

