import { Component, OnInit, Inject } from '@angular/core';
import { AuthIds4Service } from '../_services/authids4.service';
import { Router } from '@angular/router';
import { ConfigService } from '../_services/config.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-loggedout',
  template: ''
})
export class LoggedoutComponent implements OnInit {
  constructor(
    private authIds4Service: AuthIds4Service,
    private router: Router,
    private cfgService: ConfigService
  ) {}

  ngOnInit() {
    // Receives the callback from Ids4 to complete the login handshake
    // Url define at 'environment.ids4RedirectUrl'
    this.authIds4Service
      .logoutCallback()
      .then(value => {
        this.authIds4Service.setLoggedUser(null);

        const signupUrl = this.cfgService.config.signup.baseUrl;
        const url = signupUrl.replace('signup.', '');
        window.top.location.href = url;
        
      })
      .catch(reason => {
        this.router.navigate(['/login']);
      });
  }
}
