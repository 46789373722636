import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ISkin } from '../_models/template-family.models';

@Component({
  selector: 'app-color-block',
  templateUrl: './color-block.component.html',
  styleUrls: ['./color-block.component.scss']
})
export class ColorBlockComponent implements OnInit {
  @Input() color: ISkin;
  @Input() selected: boolean;

  @Output() selectSkinColor = new EventEmitter<ISkin>();

  constructor() {
  }

  ngOnInit(): void { }

  manageClick(): void {
    this.selectSkinColor.emit(this.color);
  }
}
