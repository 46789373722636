<div class="main-content container-fluid">
  <div class="dashboard-section container-fluid">
    <div class="header row">
      <div class="header-text col">{{ this.translator.translate('congratulations') | async }}</div>
    </div>

    <div class="subheader row">
      <a href="{{surveyAddress}}" target="_blank">{{ this.translator.translate('view_your_survey') | async }}</a> <span>&nbsp;{{ this.translator.translate('in_another_tab') | async }}</span>
    </div>

    <div class="btn-section row">
      <a (click)="this.goToDashboard()">{{this.translator.translate('go_to_the_dashboard_now') | async }}</a>
    </div>
  </div>

  <div class="container-fluid loading-screen-icon">
    <div class="row">
      <img class="hexa-screen" src="./assets/images/loading-screen/loading_screen_hexa.svg" alt="Logo" />
    </div>
  </div>

  <div class="hexa-app-section container-fluid">
    <div class="text-section container-fluid">
      <div class="heading row">{{this.translator.translate('get_the_hexa_app') | async }}</div>

      <div class="subheading row">{{this.translator.translate('to_listen_to_your_customers_at_all_times') | async }}</div>

      <div class="app-store-btns-section row">
        <div class="apple-store col">
          <a target="_blank" href="https://apps.apple.com/ca/app/hexa/id1486597807">
            <img class="store-logo" src="assets/images/loading-screen/appstore_logo.png" />
          </a>
        </div>

        <div class="android-store col">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.lanla.hexa.Production">
            <img class="store-logo" src="assets/images/loading-screen/googleplay_logo.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
