import { User } from 'oidc-client';
import { UserDto } from '../_models/user';

// Creating extensions for User
declare module 'oidc-client' {
  interface User {
    getUserDto(this: User): UserDto;
  }
}

// Copying the values of user's model
// Only the model is referenced from the UI
function getUserDto(this: User): UserDto {
  const dto: UserDto = new UserDto();

  if (this != null && this.profile != null) {
    dto.id = this.profile.sub;
    dto.familyName = this.profile.family_name;
    dto.givenName = this.profile.given_name;
    dto.name = this.profile.name;
    dto.userName = this.profile.preferred_username;
    dto.website = this.profile.website;
    dto.roles = this.profile.role;
    dto.jobTitle = this.profile.job_title;
    dto.timeZone = this.profile.time_zone;
    dto.culture = this.profile.culture;
    dto.authScheme = this.profile.auth_scheme;
    dto.ctEnabled = this.profile.ct_enable;
  }

  return dto;
}

User.prototype.getUserDto = getUserDto;

export {};
