import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SurveyBuilderClient, FileParameter, SurveyBuildParametersDto, BuildSurveyViewModel, PredefinedFlowDto } from '../signup-api.g';
import { ConfigService } from '../_services/config.service';
import { TranslatorService } from '../_services/translator-service';
import { ColorsListDefault, ColorsListPlain } from './_models/colors-list.config';
import { FamilyTemplates, ISkin, ITemplateFamily } from './_models/template-family.models';

@Component({
  selector: 'app-survey-editor-screen',
  templateUrl: './survey-editor-screen.component.html',
  styleUrls: ['./survey-editor-screen.component.scss'],
})
export class SurveyEditorScreenComponent implements OnInit, OnDestroy, OnChanges {
  private saveRequestSubscription: Subscription;
  private initializationSubscription: Subscription;

  private canSaveFlag = false;
  // Used to store last upload attempt without neccesarily losing the last good logo preview if there
  // was an error in the process.
  private uploadedLogo: string | ArrayBuffer;
  public uploadError = false;
  public welcomePopup: boolean;
  public selectedSkinColor: ISkin;
  public editSurveyTitle: boolean;
  public surveyTitleEN: string;
  public surveyTitleFR: string;
  public surveyTitleCurrentLang: string;
  public editIntroductionText: boolean;
  public introductionTextEN: string;
  public introductionTextFR: string;
  public introductionTextCurrentLang: string;
  public uploadLogoTrigger: boolean;
  public uploadedLogoPreview: string | ArrayBuffer;
  public vocBaseUrl: string;
  public backgroundImageUrl: string;
  public selectedFlow: string;

  public selectedTemplate: ITemplateFamily;
  public templateFamilies: ITemplateFamily[];
  public defaultTemplate: ITemplateFamily;
  public predefinedFlows: PredefinedFlowDto[] = [];

  public overrideTemplatesDisplay = false;

  @Input() public saveRequest: Subject<void>;
  @Input() public subscriptionServiceId: string;
  @Input() public defaultBusinessName: string;
  @Input() public initializationRequest: Subject<void>;
  @Output() public saveComplete = new EventEmitter<BuildSurveyViewModel>();
  @Output() public canSaveStateChange = new EventEmitter<boolean>();

  constructor(private surveyBuilderClient: SurveyBuilderClient, public translator: TranslatorService, public configService: ConfigService) {
    this.welcomePopup = false;
    this.vocBaseUrl = this.configService.config.voc.baseUrl;
    this.editSurveyTitle = false;
    this.surveyTitleEN = 'Welcome to our satisfaction survey';
    this.surveyTitleFR = 'Bienvenue au sondage de satisfaction';

    this.editIntroductionText = false;
    this.introductionTextEN = 'Please take two minutes to complete this survey and help us improve our services!';
    this.introductionTextFR = 'Veuillez prendre deux minutes pour répondre à notre sondage afin de nous aider à améliorer nos services!';

    this.translator.activeLanguage.listen((lang) => {
      if (lang === 'fr') {
        this.surveyTitleCurrentLang = this.surveyTitleFR;
        this.introductionTextCurrentLang = this.introductionTextFR;
      } else {
        this.surveyTitleCurrentLang = this.surveyTitleEN;
        this.introductionTextCurrentLang = this.introductionTextEN;
      }
    });
  }

  ngOnInit(): void {
    this.overrideTemplatesDisplay = false;
    this.templateFamilies = new FamilyTemplates().families;
    this.defaultTemplate = this.templateFamilies.find((f) => f.default);
    this.selectedSkinColor = this.defaultTemplate.colorScheme[0];
    this.selectedTemplate = this.defaultTemplate;
    this.backgroundImageUrl = 'url("' + this.getImageUrl('background-' + this.selectedSkinColor['template-name'] + '.jpg') + '")';
  }

  ngOnDestroy(): void {
    if (this.saveRequestSubscription) {
      this.saveRequestSubscription.unsubscribe();
    }

    if (this.initializationSubscription) {
      this.initializationSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.initializationRequest) {
      if (this.initializationSubscription) {
        this.initializationSubscription.unsubscribe();
      }

      this.initializationSubscription = this.initializationRequest.subscribe(() => {
        this.verifySaveState();

        this.surveyBuilderClient.getLogo().subscribe((x) => {
          if (x != null) {
            const reader = new FileReader();
            reader.readAsDataURL(x.data);
            reader.onload = (event) => {
              // called once readAsDataURL is completed
              this.uploadedLogo = event.target.result;
              this.uploadedLogoPreview = this.uploadedLogo;
            };
          }
        });

        if (!this.predefinedFlows || this.predefinedFlows.length === 0) {
          this.surveyBuilderClient.getPredefinedFlows().subscribe((result) => {
            if (result.flows) {
              result.flows.forEach((element) => {
                this.predefinedFlows.push(element);
              });

              this.selectedFlow = this.predefinedFlows[0].id;
              this.verifySaveState();
            }
          });
        }
      });
    }

    if (changes && changes.saveRequest) {
      if (this.saveRequestSubscription) {
        this.saveRequestSubscription.unsubscribe();
      }

      // Set subscription for when the next button at the top is clicked.
      this.saveRequestSubscription = this.saveRequest.subscribe((observable) => {
        try {
          const colors: { [key: string]: string } = {};
          Object.entries(this.selectedSkinColor).forEach((keyValue) => (colors[keyValue[0]] = keyValue[1]));

          this.surveyBuilderClient
            .build(
              new SurveyBuildParametersDto({
                voiceOfCustomerServiceId: this.subscriptionServiceId,
                predefinedFlowId: this.selectedFlow,
                colors,
                titleEn: this.surveyTitleEN,
                titleFr: this.surveyTitleFR,
                introEn: this.introductionTextEN,
                introFr: this.introductionTextFR,
              })
            )
            .subscribe((result) => {
              this.saveComplete.emit(result);
            });
        } catch (err) {
          console.error(err);
          this.saveComplete.emit(null);
        }
      });
    }
  }

  set canSave(value: boolean) {
    this.canSaveFlag = value;
    this.canSaveStateChange.emit(this.canSave);
  }

  get canSave(): boolean {
    return this.canSaveFlag;
  }

  private getImageUrl(fileName: string): string {
    return `${this.vocBaseUrl}/assets/img/saas/${fileName}`;
  }

  verifySaveState(): void {
    if (this.welcomePopup) {
      this.canSave = false;
      return;
    }

    if (!this.selectedTemplate) {
      this.canSave = false;
      return;
    }

    this.canSave = true;
  }

  public dismissPopupClick() {
    this.welcomePopup = false;
    this.uploadLogoTrigger = false;
  }

  public stopPropagationClick(event: any) {
    // Only to prevent bubbling the event which would close the modal.
    event.stopPropagation();
  }

  uploadLogo(event: any) {
    this.uploadLogoTrigger = !this.uploadLogoTrigger;
    // Only to prevent bubbling the event which would close the modal when trying to open it.
    event.stopPropagation();
  }

  manageWelcomeClick(): void {
    this.welcomePopup = false;
    this.verifySaveState();
  }

  manageEditSurveyTitleClick(): void {
    this.editSurveyTitle = !this.editSurveyTitle;
  }

  manageEditIntroductionTextClick(): void {
    this.editIntroductionText = !this.editIntroductionText;
  }

  setSkinColor(color: ISkin): void {
    this.selectedSkinColor = color;

    if (this.selectedTemplate.noBackground) {
      this.backgroundImageUrl = '';
      return;
    }

    this.backgroundImageUrl = 'url("' + this.vocBaseUrl + '/assets/img/saas/background-' + this.selectedSkinColor['template-name'] + '.jpg")';
  }

  setTemplate(template: ITemplateFamily): void {
    this.selectedTemplate = template;
    this.selectedFlow = this.predefinedFlows.find((f) => f.code === this.selectedTemplate.name).id;

    this.setSkinColor(this.selectedTemplate.colorScheme[0]);
  }

  setSurveyTitleEN(title: string): void {
    this.surveyTitleEN = title;
    if (this.translator.activeLanguage.value === 'en') {
      this.surveyTitleCurrentLang = title;
    }
  }

  setSurveyTitleFR(title: string): void {
    this.surveyTitleFR = title;
    if (this.translator.activeLanguage.value === 'fr') {
      this.surveyTitleCurrentLang = title;
    }
  }

  setIntroductionTextEN(text: string): void {
    this.introductionTextEN = text;
    if (this.translator.activeLanguage.value === 'en') {
      this.introductionTextCurrentLang = text;
    }
  }

  setIntroductionTextFR(text: string): void {
    this.introductionTextFR = text;
    if (this.translator.activeLanguage.value === 'fr') {
      this.introductionTextCurrentLang = text;
    }
  }

  setContentBackground(): any {
    return {
      backgroundImage: `linear-gradient(to right, ${this.selectedSkinColor['background-gradient-from']}, ${this.selectedSkinColor['background-gradient-to']} 84%)`,
    };
  }

  logoSelected(e: File) {
    var reader = new FileReader();

    reader.readAsDataURL(e); // read file as data url
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.uploadedLogo = event.target.result;
    };

    let fileParameter: FileParameter = { data: e, fileName: e.name };
    this.surveyBuilderClient
      .uploadLogo(fileParameter)
      .pipe(
        catchError((err) => {
          this.uploadError = true;
          return throwError(err);
        })
      )
      .subscribe((res) => {
        this.uploadError = false;
        this.uploadedLogoPreview = this.uploadedLogo;
      });
  }

  uploadLogoDismiss(e: any) {
    this.uploadLogoTrigger = !this.uploadLogoTrigger;
  }

  public showTemplates(): void {
    this.overrideTemplatesDisplay = !this.overrideTemplatesDisplay;
  }
}
