import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  Branch,
  Industry,
  IndustryInformation,
  QuestionType,
  SetupSubscriptionViewModel,
  SignupDetailsViewModel,
  SubscriptionClient,
  SubscriptionInformation,
} from '../signup-api.g';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-business-info-screen',
  templateUrl: './business-info-screen.component.html',
  styleUrls: ['./business-info-screen.component.scss'],
})
export class BusinessInfoScreenComponent
  implements OnInit, OnChanges, OnDestroy {
  private industryId: string;
  private industry: Industry;
  public QuestionType = QuestionType;
  public industryInformation: IndustryInformation[];
  public userSubscription: SignupDetailsViewModel;
  public hasErrors: boolean;
  public duplicateNameError: boolean;

  @Input() public saveRequest: Subject<void>;
  @Output() public saveComplete = new EventEmitter<SignupDetailsViewModel>();
  @Output() public canSaveStateChange = new EventEmitter<boolean>();

  saveRequestSubscription: Subscription;

  public restaurantName: string;
  public numberOfEmployees: string;
  public dropdownEmployees = [];
  public restaurantType: string;
  public dropdownType = [];
  public haveWebsite: boolean;
  public dropdownWebsite = [];

  public address: string;
  public city: string;
  public postalCode: string;
  public province: string;
  public country: string;
  public phone: string;

  constructor(
    public translator: TranslatorService,
    private route: ActivatedRoute,
    private subscriptionClient: SubscriptionClient
  ) {
    this.restaurantName = '';
    this.numberOfEmployees = this.dropdownEmployees[0];
    this.restaurantType = this.dropdownType[0];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.industryId = params.industryId;
    });

    this.translator.activeLanguage.listen((lang) => {
      if (lang) {
        this.getNumberOfEmployeesText().then();
        this.getWebsiteText().then();
        this.getRestaurantTypeText().then();
      }
    });

    this.route.data.subscribe((data) => {
      this.userSubscription = data.userSubscription;
      if (this.userSubscription) {
        if (this.userSubscription.subscriptionInformation) {
          let target = this.userSubscription.subscriptionInformation.find(
            (s) => s.key === QuestionType.Name
          );
          this.restaurantName = target ? target.value : null;

          target = this.userSubscription.subscriptionInformation.find(
            (s) => s.key === QuestionType.NumberOfEmployees
          );
          this.numberOfEmployees = target ? target.value : null;
          this.getNumberOfEmployeesText().then();

          target = this.userSubscription.subscriptionInformation.find(
            (s) => s.key === QuestionType.HasWebsite
          );
          this.haveWebsite = target ? target.value === 'true' : null;
          this.getWebsiteText().then();

          target = this.userSubscription.subscriptionInformation.find(
            (s) => s.key === QuestionType.RestaurantType
          );
          this.restaurantType = target ? target.value : null;
          this.getRestaurantTypeText().then();
        }

        if (this.userSubscription.businessName) {
          this.restaurantName = this.userSubscription.businessName;
        }

        if (this.userSubscription.branch) {
          this.address = this.userSubscription.branch.address;
          this.city = this.userSubscription.branch.city;
          this.province = this.userSubscription.branch.department;
          this.country = this.userSubscription.branch.country;
          this.postalCode = this.userSubscription.branch.postalCode;
          this.phone = this.userSubscription.branch.phone;
        }

        this.canSaveStateChange?.emit(true);
      }

      data.industryInformation.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }

        if (a.order < b.order) {
          return -1;
        }

        return 0;
      });

      this.industryInformation = data.industryInformation;
      this.industry = data.industry;
    });
  }

  getNumberOfEmployeesText(): Promise<void> {
    return Promise.all([
      this.translator.translate('1_to_9'),
      this.translator.translate('10_to_24'),
      this.translator.translate('25_to_49'),
      this.translator.translate('50+'),
    ]).then((observer) => {
      this.dropdownEmployees = observer;
    });
  }

  getWebsiteText(): Promise<void> {
    return Promise.all([
      this.translator.translate('have_a_website'),
      this.translator.translate('dont_have_a_website'),
    ]).then((observer) => {
      this.dropdownWebsite = observer;
    });
  }

  getRestaurantTypeText(): Promise<void> {
    return Promise.all([
      this.translator.translate('casual'),
      this.translator.translate('fast_casual'),
      this.translator.translate('quick_service'),
      this.translator.translate('fine_dining'),
    ]).then((observer) => {
      this.dropdownType = observer;
    });
  }

  getBusinessNameText(): Promise<string> {
    const industryCode = this.industry.code;
    return this.translator.translate(`business-name-${industryCode}`);
  }

  getBusinessInformationText(): Promise<string> {
    const industryCode = this.industry.code;
    return this.translator.translate(`information-${industryCode}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.saveRequest) {
      if (this.saveRequestSubscription) {
        this.saveRequestSubscription.unsubscribe();
      }

      this.saveRequestSubscription = this.saveRequest.subscribe(
        (observable) => {
          this.hasErrors = false;
          this.duplicateNameError = false;

          try {
            this.saveSubscriptionInfo(this.buildSubscriptionInfo())
              .pipe(catchError(
                err => {
                  if (err.status === 409) {
                    this.duplicateNameError = true;
                  }

                  return throwError(err);
                }
              ))
              .subscribe(
                (result) => {
                  this.saveComplete.emit(result);
                },
                (err) => {
                  if (err instanceof HttpErrorResponse) {
                    console.log(err.status);
                  }

                  console.log('HTTP Error', err);
                  throw err;
                }
              );
          } catch (err) {
            console.error(err);
            this.saveComplete.emit(null);
            this.hasErrors = true;
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.saveRequestSubscription.unsubscribe();
  }

  updateRestaurantName(name: string): void {
    this.restaurantName = name;
    this.canSave();
  }

  updateAddress(value: string): void {
    this.address = value;
  }

  updateCity(value: string): void {
    this.city = value;
  }

  updatePostalCode(value: string): void {
    this.postalCode = value;
  }

  updateProvince(value: string): void {
    this.province = value;
  }

  updateCountry(value: string): void {
    this.country = value;
  }

  updatePhone(value: string): void {
    this.phone = value;
  }

  setNumberOfEmployees(selection: string): void {
    this.numberOfEmployees = selection;
    this.canSave();
  }

  setRestaurantType(selection: string): void {
    this.restaurantType = selection;
    this.canSave();
  }

  setHaveWebsite(selection: string): void {
    this.haveWebsite = selection === this.dropdownWebsite[0];
  }

  canSave() {
    if (this.allFieldsEntered()) {
      this.canSaveStateChange?.emit(true);
    } else {
      this.canSaveStateChange?.emit(false);
    }
  }

  allFieldsEntered(): boolean {
    if ((this.restaurantType || !this.industryInformation.some(q => q.questionType == QuestionType.RestaurantType))
      && (this.numberOfEmployees || !this.industryInformation.some(q => q.questionType == QuestionType.NumberOfEmployees))
      && (this.restaurantName || !this.industryInformation.some(q => q.questionType == QuestionType.Name))) {
        return true;
    }

    return false;
  }

  buildSubscriptionInfo(): SubscriptionInformation[] {
    const info: SubscriptionInformation[] = [];

    if (!this.restaurantName) {
      throw 'name-required';
    }

    info.push(
      new SubscriptionInformation({
        key: QuestionType.Name as number,
        value: this.restaurantName,
      })
    );

    if (this.restaurantType) {
      info.push(
        new SubscriptionInformation({
          key: QuestionType.RestaurantType as number,
          value: this.restaurantType,
        })
      );
    }

    if (this.haveWebsite !== undefined && this.haveWebsite !== null) {
      info.push(
        new SubscriptionInformation({
          key: QuestionType.HasWebsite as number,
          value: this.haveWebsite.toString(),
        })
      );
    }

    if (this.numberOfEmployees) {
      info.push(
        new SubscriptionInformation({
          key: QuestionType.NumberOfEmployees as number,
          value: this.numberOfEmployees,
        })
      );
    }

    return info;
  }

  saveSubscriptionInfo(si: SubscriptionInformation[]): Observable<SignupDetailsViewModel> {
    return this.subscriptionClient.setupSubscription(
      new SetupSubscriptionViewModel({
        industryId: this.industryId,
        name: this.restaurantName,
        subscriptionInformation: si,
        branch: new Branch({
          address: this.address,
          city: this.city,
          postalCode: this.postalCode,
          department: this.province,
          country: this.country,
          phone: this.phone,
        }),
      })
    );
  }
}
