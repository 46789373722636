<div
  class="template-block"
  [ngClass]="{ selected: selected, disabled: disabled }"
  (click)="manageClick()"
  [style]="{ borderColor: selectedColor ? selectedColor['border-color'] : 'unset' }"
>
  <div class="template-icon" [hidden]="disabled" [style]="{ 'background-color': selectedColor ? selectedColor['primary-color'] : 'unset' }"><img [src]="this.imageSource" [ngClass]="{ preview: !template.noBackground }" /></div>
  <div class="disabled-message" [hidden]="!disabled">{{ this.translator.translate("coming_soon_template") | async }}</div>
  <div class="checkmark" [hidden]="!selected"><img src="assets/images/survey-editor-screen/checkmark.svg" /></div>
</div>
