import { Component, OnInit, Input, Output, EventEmitter, NgZone, AfterContentChecked } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit, AfterContentChecked {
  @Input() text: string;
  @Input() error: boolean;
  @Input() errorMessage: string;
  @Input() placeholder: string;
  @Input() password: boolean;
  @Input() disabled: boolean;
  @Input() textarea = false;
  @Input() showPlaceholder = true;
  @Input() maxLength: number;

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  public refNumber: string;

  constructor(private zone: NgZone) {
    this.refNumber = Date.now().toString(36) + Math.random().toString(36).substr(2);

    this.zone.runOutsideAngular(() => {
      $(() => {
        function togglePassword(elem: any) {
          if (elem.prop('type') === 'password') {
            elem.prop('type', 'text');
          } else {
            elem.prop('type', 'password');
          }
        }

        $('input[type=password]')
          .siblings('div.preview-password')
          .unbind()
          .on('click', ele => {
            togglePassword($(ele.currentTarget).siblings('input'));
            $(ele.currentTarget).toggleClass('off');
            $(ele.currentTarget).toggleClass('on');
          });
      });
    });
  }

  ngOnInit() {
    const FloatLabel = (() => {

      // add active class and placeholder
      const handleFocus = (e) => {
        const target = e.target;
        target.parentNode.classList.add('active');
        const inputId = 'text' + this.refNumber;
        if (inputId === target.id) {
          document.getElementById('text' + this.refNumber + 1).classList.remove('lower-case');
          document.getElementById('text' + this.refNumber + 1).classList.add('label-focus');
        }
      };

      // remove active class and placeholder
      const handleBlur = (e) => {
        const target = e.target;
        if (!target.value) {
          const inputId = 'text' + this.refNumber;
          if (inputId === target.id) {
            document.getElementById('text' + this.refNumber + 1).classList.add('lower-case');
          }
          target.parentNode.classList.remove('active');
        }

        document.getElementById('text' + this.refNumber + 1).classList.remove('label-focus');
        target.removeAttribute('placeholder');
      };

      // register events
      const bindEvents = (element) => {
        const floatField = element.querySelector('input');
        floatField.addEventListener('focus', handleFocus);
        floatField.addEventListener('blur', handleBlur);
      };

      // get DOM elements
      const init = () => {
        const floatContainers = document.querySelectorAll('.float-container');

        floatContainers.forEach((element) => {
          if (element.querySelector('input').value) {
              element.classList.add('active');
          }

          bindEvents(element);
        });
      };

      return {
        init
      };
    })();

    FloatLabel.init();
  }

  emitTextChanged(value: string) {
    this.textChange.emit(this.text);
  }

  ngAfterContentChecked() {
    // if (this.text !== undefined && /\S/.test(this.text)) {
    //   document.getElementById(this.refNumber).classList.add('active');
    // }
  }

  setInputPadding() {
    let paddingValue = this.showPlaceholder ? '21px 0 4px 11px' : '12px 0 12px 11px';

    return {
      padding: paddingValue
    };
  }
}
