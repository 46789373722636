import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Branch, BuildSurveyViewModel, SetupBranchServicesViewModel, SignupDetailsViewModel } from '../signup-api.g';
import { TranslatorService } from '../_services/translator-service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-creation-steps',
  templateUrl: './creation-steps.component.html',
  styleUrls: ['./creation-steps.component.scss'],
})
export class CreationStepsComponent implements OnInit, OnDestroy {
  public enableNext = false;
  public currentStep = 1;
  public branch: Branch;
  public subscriptionServiceId: string;
  public businessName: string;
  public surveyAddress: string;
  public buildSurveyViewModel: BuildSurveyViewModel;
  public defaultBusinessName: string;
  public hasErrors = false;
  public signupDetails: SignupDetailsViewModel;
  public businessInfoSaveRequest = new EventEmitter<void>();
  public chooseServicesSaveRequest = new EventEmitter<void>();

  public surveyAddressSaveRequest = new EventEmitter<void>();
  public surveyAddressInitializationRequest = new EventEmitter<void>();

  public surveyEditorInitializationRequest = new EventEmitter<void>();

  public surveyEditorSaveRequest = new EventEmitter<void>();

  public loadingSaveRequest = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private industryId: string;

  @Output() public saveComplete = new EventEmitter<string>();

  constructor(public translator: TranslatorService, private location: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      this.industryId = r.industryId;
    });

    this.updateUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  updateStep(newStep: number): void {
    this.currentStep = newStep;
  }

  next(diff: number) {
    this.hasErrors = false;

    if (diff > 0) {
      this.enableNext = false;
      switch (this.currentStep) {
        case 1:
          this.businessInfoSaveRequest.emit();
          break;
        case 2:
          this.chooseServicesSaveRequest.emit();
          break;
        case 3:
          this.surveyAddressSaveRequest.emit();
          break;
        case 4:
          this.surveyEditorSaveRequest.emit();
          break;
        case 5:
          this.loadingSaveRequest.emit();
          break;
        default:
          this.currentStep > 4 ? this.currentStep : this.currentStep++;
          break;
      }
    } else {
      this.currentStep--;
    }

    if (this.currentStep > 1) {
      this.updateUrl();
    }
  }

  goToPreviousStep(number: number) {
    if (number < this.currentStep) {
      this.currentStep = number;
      this.updateUrl();
    }
  }

  updateUrl() {
    switch (this.currentStep) {
      case 1:
        this.location.replaceState(`industries/${this.industryId}/wizard/businessinfo`);
        break;
      case 2:
        this.location.replaceState(`industries/${this.industryId}/wizard/choosefield`);
        break;
      case 3:
        this.location.replaceState(`industries/${this.industryId}/wizard/surveyaddress`);
        break;
      case 4:
        this.location.replaceState(`industries/${this.industryId}/wizard/surveyeditor`);
        break;
      case 5:
        this.location.replaceState(`industries/${this.industryId}/wizard/publish`);
        break;
      default:
        this.location.replaceState(`industries/${this.industryId}/wizard`);
        break;
    }
  }

  businessInfoSaveComplete(result: SignupDetailsViewModel) {
    if (result) {
      this.currentStep++;
      this.signupDetails = result;

      this.branch = this.signupDetails.branch;
      this.subscriptionServiceId = this.signupDetails.subscriptionServiceId;
      this.businessName = this.signupDetails.businessName;

      this.updateUrl();
    } else {
      this.hasErrors = true;
    }
  }

  chooseServicesSaveComplete(result: SetupBranchServicesViewModel): void {
    if (result) {
      this.currentStep++;
      this.surveyAddressInitializationRequest.emit();
      this.updateUrl();
    } else {
      this.hasErrors = true;
    }
  }

  surveyAddressSaveComplete(result: string): void {
    if (result) {
      this.surveyAddress = result;
      this.currentStep++;
      this.surveyEditorInitializationRequest.emit();
    }
  }

  surveyEditorSaveComplete(result: BuildSurveyViewModel): void {
    if (result) {
      this.buildSurveyViewModel = result;
      this.currentStep++;
      this.updateUrl();
    } else {
      this.hasErrors = true;
    }
  }

  public canSave(result: boolean): void {
    this.enableNext = result;
  }
}
