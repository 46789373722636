import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-page',
  templateUrl: './plan-page.component.html',
  styleUrls: ['./plan-page.component.scss']
})
export class PlanPageComponent implements OnInit {
  public userCount: number;
  public totalPrice: number;

  constructor() {
    this.userCount = 1;
    this.totalPrice = 10;
  }

  ngOnInit(): void {
  }

  increaseCount(): void {
    this.userCount++;
    this.totalPrice += 10;
  }

  decreaseCount(): void {
    if (this.userCount !== 1) {
      this.userCount--;
      this.totalPrice -= 10;
    }
  }
}
