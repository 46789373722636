import 'src/app/_helpers/user-helper';
import 'src/app/_helpers/userdto-helper';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthIds4Service } from 'src/app/_services/authids4.service';
import { TranslatorService } from 'src/app/_services/translator-service';
import { UserClient } from 'src/app/signup-api.g';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  public selectedLanguage = 'fr';
  public showAccountPopover: boolean;
  public isLogged: boolean;
  public settingsClicked = false;
  public scrolledToTop: boolean;

  constructor(
    public authIds4Service: AuthIds4Service,
    public translator: TranslatorService,
    private router: Router,
    private location: Location
  ) {
    this.showAccountPopover = false;
    this.isLogged = false;
    this.scrolledToTop = true;

    this.translator.activeLanguage.listen(lang => {
      this.selectedLanguage = lang;
    });

    this.authIds4Service.userLoadedEvent.pipe(first()).subscribe(
      (user) => {
        if (user) {
          const userDto = user.getUserDto();

          this.authIds4Service.userName = userDto.name;
          this.isLogged = true;

          if (userDto.culture) {
            this.translator.changeLanguage(userDto.culture);
          }
        }
      });
  }

  ngOnInit(): void { }

  toggleLanguage(lang: string): void {
    this.translator.changeLanguage(lang);
  }

  toggleAccountPopover(e: any) {
    // Preventing to trigger the window.click
    e.stopPropagation();
    e.preventDefault();

    if (!this.scrolledToTop) {
      return;
    }

    this.showAccountPopover = !this.showAccountPopover;

    // if (this.showAccountPopover) {
    //   this.showOptionsPopover = false;
    // }

    //this.manageScrollables();
  }

  settingsNavigateTo() {
    this.settingsClicked = true;
    //this.manageScrollables('reset');
    this.router.navigate(['/admin/userSettings']);
  }

  settingsNavigateBack() {
    this.settingsClicked = false;

    if (window.history.length > 1) {
      this.location.back();

      setTimeout(() => window.location.reload(), 50);
    } else {
      this.router.navigate(['/']);
    }
  }

  goToAdmin() {
    this.settingsClicked = false;
    //this.manageScrollables('reset');
    this.router.navigate(['admin', 'roles']);
  }

  logout() {
    // localStorage.removeItem(`detailSelectedFilters-${this.portalEvents.subscriptionService.value.id}`);
    // localStorage.removeItem(`dashboardSelectedFilters-${this.portalEvents.subscriptionService.value.id}`);

    localStorage.clear();

    this.authIds4Service.logoutIds4();
  }
}
