<div class="field-card" [style]="fieldCardStyle()" (click)="manageClick()">
  <div class="field-card-header">
    {{ industryService.name }}
  </div>

  <div class="field-card-icon">
    <span class="plus-sign" *ngIf="!selected">+</span>
    <span class="minus-sign" *ngIf="selected">-</span>
  </div>
</div>
