import { UserDto } from '../_models/user';

// Creating extensions for UserDto
declare module '../_models/user' {
  interface UserDto {
    hasRole(this: UserDto, role: string): boolean;
  }
}

// Extension method for the UserDto class
function hasRole(this: UserDto, role: string) {
  if (this == null || this.roles == null) {
    return false;
  }

  if (this.roles.indexOf(role) > -1) {
    return true;
  } else {
    return false;
  }
}

UserDto.prototype.hasRole = hasRole;

export {};
