<div class="container upload-logo">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <h1>{{this.translator.translate('choose_your_logo') | async}}</h1>
    </div>
  </div>
  <div class="row max-height">
    <div class="col">
      <img class="img-fluid img-medium mx-auto d-block" [src]="url"/>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center mobile">
      <input type='file' accept='.jpg,.jpeg,.png' (change)="onSelectFile($event)">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn-okay" [disabled]="!url" (click)="upload()">
        <span class="btn-text"> {{this.translator.translate('upload') | async}} </span>
        <i class="caret-arrow"></i>
      </button>
      <button class="btn-okay" (click)="dismiss()">
        <span class="btn-text"> {{this.translator.translate('cancel') | async}} </span>
        <i class="caret-arrow"></i>
      </button>
    </div>
  </div>
</div>
