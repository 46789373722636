import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../_services/config.service';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-hexa-pro',
  templateUrl: './hexa-pro.component.html',
  styleUrls: ['./hexa-pro.component.scss']
})
export class HexaProComponent implements OnInit {

  public portalUrl = '';

  constructor(public translator: TranslatorService, private configService: ConfigService) {
    this.portalUrl = this.configService.config.Portal.BaseUrl;
  }

  ngOnInit(): void {
  }

}
