import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ChooseServiceScreenComponent } from 'src/app/choose-service-screen/choose-service-screen.component';
import { TranslatorService } from 'src/app/_services/translator-service';

@Component({
  selector: 'app-progress-top-bar',
  templateUrl: './progress-top-bar.component.html',
  styleUrls: ['./progress-top-bar.component.scss'],
})
export class ProgressTopBarComponent implements OnInit, OnChanges {
  @Input() currentStep = 1;
  @Input() enableNext = true; // Disable going to the next step
  @Output() updateStepRequest = new EventEmitter<number>();
  @Output() goToPreviousStepRequest = new EventEmitter<number>();

  constructor(public translator: TranslatorService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentStepChange = changes.currentStep;
    const enableNextChange = changes.enableNext;

    if (currentStepChange && currentStepChange.currentValue && this.enableNext) {
      this.currentStep = currentStepChange.currentValue;
    }

    if (enableNextChange && enableNextChange.currentValue) {
      this.enableNext = changes.enableNext.currentValue;
    }

    if (ChooseServiceScreenComponent.selectedCodesLength === 0 && this.currentStep === 2) {
      this.enableNext = false;
    }
  }

  setStepTextColor(id: number) {
    const styling = {
      color: '#b6c1c1',
    };

    if (this.currentStep === id) {
      styling.color = '#383f42';
    } else if (this.currentStep > id) {
      styling.color = '#3ab7cf';
    }

    return styling;
  }

  setNextButtonText(number: number): Promise<string> {
    let translationKey = '';

    switch (number) {
      case 4:
        translationKey = 'publish';
        break;
      case 5:
        translationKey = 'Hexa';
        this.enableNext = true;
        break;
      default:
        translationKey = 'next';
        break;
    }

    return this.translator.translate(translationKey);
  }

  goToStep(number: number) {
    // Can only go to previous steps or the next one
    if (number !== this.currentStep && number <= this.currentStep + 1) {
      // If it's next step then we can just do same as if we clicked Next
      if (number > this.currentStep) {
        this.changeStep(1);
      } else {
        this.goToPreviousStepRequest.emit(number);
      }
    }
  }

  changeStep(diff: number) {
    if (diff < 0) {
      this.enableNext = true;
    }

    this.updateStepRequest.emit(diff);
  }

  isDisabled(step: number): boolean {
    if (step <= this.currentStep + 1) {
      return false;
    } else {
      return true;
    }
  }
}
