<div class="main-content container-fluid" (click)="closeEditorClick()">
  <div class="header row">
    <div class="header-icon-container">
      <img class="header-icon" src="assets/images/icons/survey-icon.svg" />
    </div>

    <div class="header-text col">{{ this.translator.translate('your_surveys_address') | async }} <span class="header-text-black"> {{ this.translator.translate('will_be') | async }}</span></div>
  </div>

  <div class="survey-address container-fluid" (click)="toggleEditModeClick($event)" *ngIf="!editMode">
    <div class="main-section row">
      <div class="address-section col">
        <div class="address-text">
          <span>{{ displayDomain }}/</span> <span class="business-name">{{ businessName }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="edit-section" >
        <img class="icon" src="assets/images/survey-editor-screen/edit-title-icon.svg" />

        <span class="change-text">{{ this.translator.translate('change_it') | async }}</span>
      </div>
    </div>
  </div>

  <div class="survey-address-edit-mode" *ngIf="editMode">
    <div class="main-section container-fluid">
      <div class="address-section row">
        <div class="address-text">
          <span>{{ displayDomain }}/</span>
        </div>

        <div class="custom-address-input">
          <app-text-box [showPlaceholder]="false" [text]="businessName" [maxLength]="20" (textChange)="businessNameChanging($event)" (click)="stopPropagationClick($event)"></app-text-box>
        </div>
      </div>
    </div>
  </div>

  <div class="error-section" *ngIf="errorValidBusinessName">
    <div class="error-message">
      <p class="invalid">{{ this.translator.translate('this_address_is_invalid') | async }}.</p>
      <p class="error-header">{{ this.translator.translate('instead') | async }} :</p>
      <p class="color-black">{{ displayDomain }}/{{ normalizedName }} </p>
      <p class="error-header">{{ this.translator.translate('will_be_used') | async }}</p>
    </div>
  </div>

  <div class="error-section" *ngIf="errorUniqueBusinessName">
    <div class="error-message">
      <p class="invalid">{{ this.translator.translate('this_address_is_already_in_use') | async }}.</p>

      <div *ngIf="suggestionsList.length > 0">
        <p class="error-header">{{ this.translator.translate('available_addresses') | async }}</p>
        <div *ngFor="let suggestion of suggestionsList">
          <p class="color-black">{{ suggestion }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="build-survey">
    <button class="btn-build" [disabled]="!canSave" (click)="buildSurveyClicked()">{{ this.translator.translate('build_my_survey_now') | async }}</button>
  </div>
</div>
