<div class="top-bar">
  <div class="logo">
    <a routerLink="/"
      ><img class="logo-image" src="./assets/images/hexa-logo.svg" alt="Logo"
    /></a>
  </div>

  <div class="logged-user-container">
    <!-- (click)="manageScrollables('reset')" -->
    <div class="logged-user" (click)="toggleAccountPopover($event)">
      <!-- *ngIf="isLogged" -->
      <div class="connection-info">
        <span class="info-label username">{{ authIds4Service.userName }}</span>
      </div>

      <div class="avatar" [ngClass]="{ disabled: !scrolledToTop }">
        <img src="/assets/images/icons/iconmonstr-user-19.svg" />
      </div>
    </div>

    <div class="language-select">
      <div
        class="lang lang-en"
        [ngClass]="{ active: selectedLanguage === 'en' }"
        (click)="toggleLanguage('en')"
      >
        EN
      </div>
      <div class="divider">|</div>
      <div
        class="lang lang-fr"
        [ngClass]="{ active: selectedLanguage === 'fr' }"
        (click)="toggleLanguage('fr')"
      >
        FR
      </div>
    </div>
  </div>
</div>

<div class="popover-overlay" *ngIf="showAccountPopover"></div>
<!-- (click)="manageScrollables('reset')" -->

<div class="popover account" [hidden]="!showAccountPopover">
  <div class="first-row">
    <div class="profile">
      <div class="avatar">
        <img src="/assets/images/icons/iconmonstr-user-19-dark.svg" />
      </div>

      <div class="account-info">
        {{ authIds4Service.userName }}
      </div>
    </div>

    <div class="buttons-row">
      <button type="button" class="btn btn-outline-primary" (click)="logout()">
        {{ translator.translate("logout") | async }}
      </button>
    </div>
  </div>
</div>
