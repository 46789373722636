<div class="industry-card" [class.coming-soon]="isComingSoon" [ngStyle]="setCardStyle()" (click)="click()">
  <div class="industry-card-body">
    <img class="industry-card-icon" src="./industry/{{cardConfig.id}}/logo" [alt]="cardConfig.name + ' icon'">
  </div>

  <div class="industry-card-header">
    <div class="industry-card-text">{{ cardConfig.text }}</div>

    <div *ngIf="isComingSoon" class="coming-soon-text">
      <span>{{ this.translator.translate('coming_soon') | async }}</span>
    </div>
  </div>
</div>
