import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { UrlService } from '../_services/url.service';

@Injectable()
export class IdTokenHeadersInterceptor implements HttpInterceptor {
  idToken = null;

  constructor(private urlService: UrlService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.idToken = this.urlService.tokenGetter(true);

    let authReq = req.clone();

    if (this.idToken) {
      authReq = req.clone({
        setHeaders: {
          'X-id-token': this.idToken,
        },
      });
    }

    return next.handle(authReq);
  }
}
