import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { IndustryClient, IndustryService } from '../signup-api.g';


@Injectable({
  providedIn: 'root',
})
export class IndustryServicesResolver implements Resolve<IndustryService[]> {
  constructor(private industryClient: IndustryClient) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IndustryService[]> {
    return this.industryClient.getIndustryServices(route.params.industryId).toPromise();
  }
}
