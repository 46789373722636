import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { UrlService } from '../_services/url.service';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  constructor(private urlService: UrlService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    return next.handle(authReq);
  }
}
