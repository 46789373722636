import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  public requestedUrl: string;

  constructor(private configService: ConfigService) {
  }

  public tokenGetter(idToken: boolean = false) {
    if (this.configService && this.configService.config !== undefined) {

      const tokenInfo = sessionStorage.getItem(`oidc.user:${this.configService.config.ids4.authorityUrl}:${this.configService.config.ids4.clientId}`);

      if (tokenInfo) {
        const token = JSON.parse(tokenInfo);

        if (token) {
          if (idToken) {
            return token.id_token;
          }

          return token.access_token;

        } else {
          return '';
        }
      }

      return '';
    }
  }
}
