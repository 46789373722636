import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CreateSurveyAddressDto, SurveyAddress, SurveyAddressesClient } from '../signup-api.g';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-survey-address-screen',
  templateUrl: './survey-address-screen.component.html',
  styleUrls: ['./survey-address-screen.component.scss']
})
export class SurveyAddressScreenComponent implements OnInit, OnDestroy, OnChanges {
  private saveRequestSubscription: Subscription;
  private initializationSubscription: Subscription;
  private businessNameChanged: EventEmitter<string> = new EventEmitter<string>();

  private _isLoading: boolean;
  private _canSave: boolean;
  private _errorValidBusinessName: boolean;
  private _errorUniqueBusinessName: boolean;
  public suggestionsList = ["test@gmail", "test2gsem"];

  public normalizedName: string;

  public editMode: boolean;
  public businessName: string;
  public currentAddress: string;
  public displayDomain: string;

  @Input() public subscriptionServiceId: string;
  @Input() public defaultBusinessName: string;
  @Input() public saveRequest: Subject<void>;
  @Input() public initializationRequest: Subject<void>;
  @Output() public saveComplete = new EventEmitter<string>();
  @Output() public canSaveStateChange = new EventEmitter<boolean>();
  constructor(
    public translator: TranslatorService,
    private surveyAddressesClient: SurveyAddressesClient
  ) {
    this.editMode = false;
    this.businessName = '';
    this.displayDomain = '';
    this.errorUniqueBusinessName = false;
    this.normalizedName = '';
  }

  set isLoading(value: boolean) {
    const isDifferent = this.isLoading !== value;
    this._isLoading = value;

    if (isDifferent) {
      this.canSave = !this.isLoading && !this.errorUniqueBusinessName;
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set errorValidBusinessName(value: boolean) {
    this._errorValidBusinessName = value;
  }

  get errorValidBusinessName(): boolean {
    return this._errorValidBusinessName;
  }

  set errorUniqueBusinessName(value: boolean) {
    const isDifferent = this._errorUniqueBusinessName !== value;
    this._errorUniqueBusinessName = value;

    if (isDifferent) {
      this.canSave = !this.isLoading && !this.errorUniqueBusinessName;
    }
  }

  get errorUniqueBusinessName(): boolean {
    return this._errorUniqueBusinessName;
  }

  set canSave(value: boolean) {
    this._canSave = value;
    this.canSaveStateChange.emit(this.canSave);
  }

  get canSave(): boolean {
    return this._canSave;
  }

  ngOnInit(): void {

    // debounce to only trigger the logic tied to setting the business name
    // after the elapsed time.
    this.businessNameChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(text => this.setBusinessName(text));

    this.obtainDisplayDomain().subscribe((domain) => {
      this.displayDomain = domain;
    });
  }

  ngOnDestroy(): void {
    if (this.saveRequestSubscription) {
      this.saveRequestSubscription.unsubscribe();
    }

    if (this.initializationSubscription) {
      this.initializationSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.initializationRequest) {
      if (this.initializationSubscription) {
        this.initializationSubscription.unsubscribe();
      }

      this.initializationSubscription = this.initializationRequest.subscribe((() => {
        this.getSurveyAddress(this.subscriptionServiceId)
        .pipe((observable) => {
          this.isLoading = true;
          return observable;
        })
        .subscribe((surveyAddress) => {
          // If one was already used by the customer, then set it to this one.
          if (surveyAddress) {
            this.businessName = surveyAddress.surveyPath;
            // Technically it should already be normalized.
            this.setNormalizedBusinessName(surveyAddress.surveyPath);
            this.currentAddress = surveyAddress.url;
            this.isLoading = false;
          } else {
            this.normalize(this.businessName)
            .pipe((observable) => {
              this.isLoading = true;
              return observable;
            })
            .subscribe((normalizedName) => {
              // On initialization set the normalized name to be business name to avoid
              // showing any error message. We can think of initial valid suggested name to be
              // the normalized business name.
              this.businessName = normalizedName;
              this.setNormalizedBusinessName(normalizedName);
              this.verify(this.subscriptionServiceId, this.normalizedName);
            });
          }
        });
      }));
    }

    if (changes && changes.defaultBusinessName) {
      this.businessName = this.defaultBusinessName?.toLowerCase()?.replace(/\s/g, "");
    }

    if (changes && changes.saveRequest) {
      if (this.saveRequestSubscription) {
        this.saveRequestSubscription.unsubscribe();
      }

      // Set subscription for when the next button at the top is clicked.
      this.saveRequestSubscription = this.saveRequest.subscribe(
        (observable) => {
          try {
            this.register(this.subscriptionServiceId, this.normalizedName).subscribe((result) => {
              this.saveComplete.emit(result);
            });
          } catch (err) {
            console.error(err);
            this.saveComplete.emit(null);
          }
        }
      );
    }
  }

  public toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  public businessNameChanging(name: string): void {
    this.isLoading = true;
    this.businessNameChanged.emit(name.toLowerCase());
  }

  public buildSurveyClicked(): void {
    try {
      this.register(this.subscriptionServiceId, this.normalizedName).subscribe((result) => {
        this.saveComplete.emit(result);
      });
    } catch (err) {
      console.error(err);
      this.saveComplete.emit(null);
    }
  }


  public toggleEditModeClick(event: any) {
    this.toggleEditMode();

    // Stop the propagation to properly detect when clicked on the element vs outside the element
    // which will close the editor.
    event.stopPropagation();
  }

  public stopPropagationClick(event: any) {
    // Only to prevent bubbling the event which would close the editor when trying to open it.
    event.stopPropagation();
  }

  public closeEditorClick() {
    this.editMode = false;
  }

  setNormalizedBusinessName(name: string): void {
    this.normalizedName = name;
    this.errorValidBusinessName = this.normalizedName !== this.businessName;
  }

  setBusinessName(name: string): void {
    this.businessName = name;
    this.normalize(name).subscribe((normalizedName) => {
      this.setNormalizedBusinessName(normalizedName);
      this.verify(this.subscriptionServiceId, this.normalizedName);
    });
  }

  verify(subscriptionServiceId: string, name: string): Subscription {
    return this.surveyAddressesClient.verify(name, subscriptionServiceId).subscribe((result) => {
      this.errorUniqueBusinessName = !result.isAvailable;
      this.currentAddress = result.requestedUrl;
      this.suggestionsList = result.suggestions.map(x => x.address);
      this.isLoading = false;
    });
  }

  register(subscriptionServiceId: string, name: string): Observable<string> {
    return this.surveyAddressesClient.register(new CreateSurveyAddressDto({
      voiceOfCustomerServiceId: subscriptionServiceId,
      name: name
    }));
  }

  obtainDisplayDomain(): Observable<string> {
    return this.surveyAddressesClient.surveyHost();
  }

  normalize(name: string): Observable<string> {
    return this.surveyAddressesClient.normalizeName(name);
  }

  getSurveyAddress(subscriptionServiceId: string): Observable<SurveyAddress> {
    return this.surveyAddressesClient.get(subscriptionServiceId);
  }
}
